<template>
  <div class="d-flex flex-wrap gap-3 align-items-center" :class="[color]">
    <h4 class="mb-0">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "text-primary",
    },
  },
};
</script>
