<template>
	<div>
		<div v-if="responsible != null" class="row mb-5">
			<div class="col-12 col-md-6 col-lg-4">
				<UserCard
					:title="responsible.name"
					:subtitle="'Mi mánager'"
					:showStatus="false"
				>
				</UserCard>
			</div>
		</div>

		<TotalAndSearch
			:title="'Invitados nuevos'"
			:total="user_guests.list?.length"
			:showActionButton="false"
			@actionButtonClick="addGuest"
			@search="search"
		/>

		<div v-if="user_guests.list?.length" class="row mt-0 gy-4">
			<div
				v-for="(guest, index) in user_guests.list"
				:key="index"
				class="col-12 col-md-6 col-lg-4"
			>
				<UserCard
					:statusText="statusName(guest.status)"
					:cardBg="cardBg(guest.status)"
					:title="guest.user.name"
					:subtitle="guest.job"
				>
					<template #action>
						<div
							v-if="
								userLogged.role.name == 'manager' && guest.status == 'pending'
							"
							class="d-flex gap-4"
						>
							<Button
								@click="updateStatus(guest.id, 'rejected')"
								class="bg-salmon text-white"
								label="Rechazar"
							/>
							<Button
								@click="updateStatus(guest.id, 'approved')"
								class="bg-green text-white"
								label="Aprobar"
							/>
						</div>
						<Button
							v-else-if="userLogged.role.name == 'empleado'"
							class="bg-primary text-white mx-auto"
							label="Validar"
						/>
					</template>
				</UserCard>
			</div>

			<div
				v-if="showLoadMore"
				class="col-12 d-flex justify-content-center mt-5"
			>
				<Button
					@click="loadMore"
					label="Ver más"
					class="bg-primary text-white"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import UserCard from "@/components/user/partials/UserCard.vue";
	import TotalAndSearch from "@/components/user/partials/TotalAndSearch.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			UserCard,
			TotalAndSearch,
		},
		data() {
			return {
				rows: 3,
				page: 0,
				lastSearch: null,
				responsible: null,
			};
		},
		methods: {
			...mapActions([
				"getRegisters",
				"updateGuestStatus",
				"getEmployeeResponsible",
			]),
			...mapMutations(["toggleFormDialog", "toggleLoading"]),
			addGuest() {
				this.toggleFormDialog({
					stateVariable: "user_guests",
					show: true,
				});
			},
			loadMore() {
				this.toggleLoading();

				this.page++;

				this.getRegisters({
					stateVariable: "user_guests",
					route: "guests",
					page: this.page,
					rows: this.rows,
					concatList: true,
					search: this.lastSearch,
					additionalParams: {
						selectedEmployee: this.manager.selectedEmployee?.user.id,
					},
				}).then(() => {
					this.toggleLoading();
				});
			},
			search(params) {
				this.lastSearch = params;
				this.toggleLoading();

				this.page = 0;

				this.getRegisters({
					stateVariable: "user_guests",
					route: "guests",
					page: this.page,
					rows: this.rows,
					search: params,
					additionalParams: {
						selectedEmployee: this.manager.selectedEmployee?.user.id,
					},
				}).then(() => {
					this.toggleLoading();
				});
			},
			cardBg(status) {
				switch (status) {
					case "pending":
						return "bg-white";

					case "approved":
						return "bg-lightgreen";

					case "rejected":
						return "bg-lightsalmon";
				}
			},
			statusName(status) {
				switch (status) {
					case "pending":
						return "Pendiente";

					case "approved":
						return "Aprobado";

					case "rejected":
						return "Rechazado";
				}
			},
			updateStatus(id, status) {
				this.updateGuestStatus({
					id,
					status,
				}).then(() => {
					this.search(this.lastSearch);

					this.getRegisters({
						stateVariable: "user_defined_guests",
						route: "guests/defined",
						page: 0,
						rows: 3,
						additionalParams: {
							selectedEmployee: this.manager.selectedEmployee?.user.id,
						},
					});
				});
			},
			clearPagination() {
				this.page = 0;
				this.rows = 3;
			},
		},
		computed: {
			...mapState(["user_guests", "manager", "userLogged"]),
			showLoadMore() {
				return this.user_guests.listTotal > this.rows * (this.page + 1);
			},
		},
		mounted() {
			let userGuestsReady,
				employeeResponisbleReady = false;

			this.getRegisters({
				stateVariable: "user_guests",
				route: "guests",
				page: this.page,
				rows: this.rows,
				additionalParams: {
					selectedEmployee: this.manager.selectedEmployee?.user.id,
				},
			}).then(() => {
				userGuestsReady = true;

				if (userGuestsReady && employeeResponisbleReady) {
					this.$emit("ready");
				}
			});

			this.getEmployeeResponsible().then((response) => {
				this.responsible = response.data.responsible;

				employeeResponisbleReady = true;

				if (userGuestsReady && employeeResponisbleReady) {
					this.$emit("ready");
				}
			});
		},
	};
</script>
