<template>
	<div :style="cssProps">
		<div @click="toggle" class="custom-accordion cursor-pointer">
			<slot name="header"></slot>
		</div>
		<div
			ref="panel"
			class="custom-panel"
			:class="[active ? 'no-max-height' : 'max-height-0']"
		>
			<slot name="body"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			index: {
				type: Number,
				required: false,
			},
		},
		data() {
			return {
				active: false,
				cssProps: null,
			};
		},
		methods: {
			toggle() {
				this.active = !this.active;
				this.$emit("toggleActive", this.active, this.index);
			},
		},
		mounted() {
			const scrollHeight = this.$refs.panel.scrollHeight;
			this.cssProps = { "--scrollHeight": scrollHeight + "px" };
		},
	};
</script>

<style lang="scss" scoped>
	.custom-accordion {
		transition: $transition;
	}

	.custom-panel {
		overflow: hidden;
		transition: $transition;

		&.max-height-0 {
			max-height: 0px;
		}

		&.no-max-height {
			max-height: var(--scrollHeight);
		}
	}
</style>
