<template>
	<div>
		<NewGuestDialog :stateVariable="'user_guests'" :route="'guests'" />

		<TotalAndSearch
			:title="'Invitados definidos'"
			:actionButtonLabel="'Añadir invitado'"
			:total="user_defined_guests.list?.length"
			@actionButtonClick="addGuest"
			@search="search"
		/>

		<div v-if="user_defined_guests.list?.length" class="row mt-0 gy-4">
			<div
				v-for="(guest, index) in user_defined_guests.list"
				:key="index"
				class="col-12 col-md-6 col-lg-4"
			>
				<UserCard
					:statusText="statusName(guest.status)"
					:cardBg="cardBg(guest.status)"
					:title="guest.user.name"
					:subtitle="guest.job"
				/>
			</div>

			<div
				v-if="showLoadMore"
				class="col-12 d-flex justify-content-center mt-5"
			>
				<Button
					@click="loadMore"
					label="Ver más"
					class="bg-primary text-white"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import UserCard from "@/components/user/partials/UserCard.vue";
	import TotalAndSearch from "@/components/user/partials/TotalAndSearch.vue";
	import NewGuestDialog from "@/components/user/partials/dialogs/NewGuestDialog.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			UserCard,
			TotalAndSearch,
			NewGuestDialog,
		},
		data() {
			return {
				rows: 3,
				page: 0,
				lastSearch: null,
				responsible: null,
			};
		},
		methods: {
			...mapActions(["getRegisters", "updateGuestStatus"]),
			...mapMutations(["toggleFormDialog", "toggleLoading"]),
			addGuest() {
				this.toggleFormDialog({
					stateVariable: "user_guests",
					show: true,
				});
			},
			loadMore() {
				this.toggleLoading();

				this.page++;

				this.getRegisters({
					stateVariable: "user_defined_guests",
					route: "guests/defined",
					page: this.page,
					rows: this.rows,
					concatList: true,
					search: this.lastSearch,
					additionalParams: {
						selectedEmployee: this.manager.selectedEmployee?.user.id,
					},
				}).then(() => {
					this.toggleLoading();
				});
			},
			search(params) {
				this.lastSearch = params;
				this.toggleLoading();

				this.page = 0;

				this.getRegisters({
					stateVariable: "user_defined_guests",
					route: "guests/defined",
					page: this.page,
					rows: this.rows,
					search: params,
					additionalParams: {
						selectedEmployee: this.manager.selectedEmployee?.user.id,
					},
				}).then(() => {
					this.toggleLoading();
				});
			},
			cardBg(status) {
				switch (status) {
					case "pending":
						return "bg-white";

					case "approved":
						return "bg-lightgreen";

					case "rejected":
						return "bg-lightsalmon";
				}
			},
			statusName(status) {
				switch (status) {
					case "pending":
						return "Pendiente";

					case "approved":
						return "Aprobado";

					case "rejected":
						return "Rechazado";
				}
			},
			updateStatus(id, status) {
				this.updateGuestStatus({
					id,
					status,
				}).then(() => {
					this.search(this.lastSearch);
				});
			},
			clearPagination() {
				this.page = 0;
				this.rows = 3;
			},
		},
		computed: {
			...mapState(["user_defined_guests", "manager", "userLogged"]),
			showLoadMore() {
				return this.user_defined_guests.listTotal > this.rows * (this.page + 1);
			},
		},
		mounted() {
			this.getRegisters({
				stateVariable: "user_defined_guests",
				route: "guests/defined",
				page: this.page,
				rows: this.rows,
				additionalParams: {
					selectedEmployee: this.manager.selectedEmployee?.user.id,
				},
			}).then(() => {
				this.$emit("ready");
			});
		},
	};
</script>
