<template>
	<div class="d-flex flex-wrap align-items-center justify-content-between">
		<div class="d-flex flex-wrap align-items-center gap-3 mb-3 mb-md-0">
			<h3 v-if="title != null" class="title mb-0 text-primary">{{ title }}</h3>
			<p v-if="showTotal" class="text mb-0">Total: {{ total }}</p>
			<div v-if="showSearch" class="col-12 col-md-6 col-lg-4">
				<span class="w-100 p-input-icon-left">
					<i class="pi pi-search" />
					<InputText
						class="w-100"
						type="text"
						v-model="search"
						:placeholder="searchPlaceholder"
					/>
				</span>
			</div>
		</div>
		<Button
			v-if="showActionButton"
			@click="action"
			:class="[actionButtonBg, actionButtonColor]"
			:label="actionButtonLabel"
		/>
	</div>
</template>

<script>
	import InputText from "primevue/inputtext";

	export default {
		components: {
			InputText,
		},
		props: {
			title: {
				type: String,
				default: null,
			},
			showTotal: {
				type: Boolean,
				default: true,
			},
			total: {
				type: Number,
				default: 0,
			},
			searchPlaceholder: {
				type: String,
				default: "Buscar",
			},
			showActionButton: {
				type: Boolean,
				default: true,
			},
			actionButtonLabel: {
				type: String,
				default: "Añadir registro",
			},
			actionButtonBg: {
				type: String,
				default: "bg-yellow",
			},
			actionButtonColor: {
				type: String,
				default: "text-white",
			},
			showSearch: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				search: null,
				timeout: null,
			};
		},
		methods: {
			action() {
				this.$emit("actionButtonClick");
			},
		},
		watch: {
			search: function (newVal) {
				clearTimeout(this.timeout);

				this.timeout = setTimeout(() => {
					this.$emit("search", newVal);
				}, 500);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.title {
		transform: translateY(-2px);
	}

	.text {
		transform: translateY(2px);
	}

	@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.text {
			transform: unset;
		}
	}
</style>
