<template>
	<TableDefault
		ref="tableDefault"
		:route="route"
		:stateVariable="stateVariable"
		:list="users.list"
		:total="users.listTotal"
		:filters="filters"
		:delete="'users'"
		:reloadByFilters="reloadByFilters"
	>
		<template #columns>
			<Column header="Nombre" field="name"></Column>
			<Column header="Rol">
				<template #body="slotProps">
					<Tag
						v-if="slotProps.data.role.name == 'super_admin'"
						:value="slotProps.data.role.slug"
					></Tag>
					<Tag
						v-else-if="slotProps.data.role.name == 'admin'"
						:value="slotProps.data.role.slug"
						class="bg-gray"
					></Tag>
					<Tag
						v-else-if="slotProps.data.role.name == 'manager'"
						:value="slotProps.data.role.slug"
						class="bg-green"
					></Tag>
					<Tag
						v-else-if="slotProps.data.role.name == 'empleado'"
						:value="slotProps.data.role.slug"
						class="bg-yellow"
					></Tag>
					<Tag
						v-else-if="slotProps.data.role.name == 'invitado'"
						:value="slotProps.data.role.slug"
						class="bg-salmon"
					></Tag>
				</template>
			</Column>
			<Column header="Empresa">
				<template #body="slotProps">
					<span v-if="slotProps.data.customer">
						{{ slotProps.data.customer.name }}
					</span>
					<span v-else-if="slotProps.data.customer_has_user">
						{{ slotProps.data.customer_has_user.customer.name }}
					</span>
					<span v-else class="text-muted">Sin empresa</span>
				</template>
			</Column>
			<Column header="Email" field="email"></Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import Tag from "primevue/tag";

	import { mapState, mapActions } from "vuex";

	export default {
		components: {
			Column,
			Tag,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "name",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Email",
					},
					{
						name: "role_id",
						value: null,
						type: "dropdown",
						placeholder: "Rol",
						options: [],
					},
				],
				reloadByFilters: false,
			};
		},
		methods: {
			...mapActions(["incrementViews", "getRegisters"]),
		},
		computed: {
			...mapState(["users", "userLogged", "roles"]),
		},
		mounted() {
			this.getRegisters({
				route: "roles",
				stateVariable: "roles",
			}).then(() => {
				this.reloadByFilters = true;
			});
		},
		watch: {
			"roles.list": function (val) {
				this.filters[2].options = val.map((role) => {
					return {
						label: role.slug,
						value: role.id,
					};
				});
			},
		},
	};
</script>
