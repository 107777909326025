<template>
	<div>
		<FeedbackDialog :stateVariable="user_feedback" />

		<TotalAndSearch
			:title="'Feedback'"
			:showActionButton="false"
			:showSearch="false"
			:total="employee.totalFeedbacks"
		/>

		<Card class="mt-3">
			<template #content>
				<div class="d-flex align-items-center justify-content-between">
					<h3 class="mb-0 text-primary">Puntuación total</h3>
					<Rating
						:margin="1"
						:defaultValue="totalAverage"
						:readonly="true"
						:roundStartRating="false"
					/>
				</div>
			</template>
		</Card>

		<div
			v-for="(section, index) in startFeedback.sectionsNames"
			:key="index"
			class="bg-white rounded mt-3 px-4"
		>
			<div class="cursor-pointer accordion w-100">
				<div
					class="py-3 d-flex flex-wrap align-items-center justify-content-between"
				>
					<div class="col-6 col-md-4">
						<h5 class="mb-0 text-primary">
							<b>{{ section.slug }}</b>
						</h5>
					</div>
					<div
						class="col-6 col-md-4 d-flex justify-content-end justify-content-md-center"
					>
						<Rating
							class="w-50"
							:starSize="20"
							:defaultValue="sectionAverage(section.id)"
							:readonly="true"
							:roundStartRating="false"
						/>
					</div>
					<div class="col-md-4 d-none d-md-flex justify-content-end">
						<i class="pi pi-angle-down text-yellow h3 mb-0"></i>
					</div>
				</div>
			</div>
			<div class="panel">
				<div
					v-for="(question, index2) in questions(index)"
					:key="index2"
					class="py-3 d-flex flex-wrap align-items-center"
				>
					<div class="col-6 col-md-4">
						<h5 class="mb-0 text-primary">{{ question.question }}</h5>
					</div>
					<div
						class="col-6 col-md-4 d-flex justify-content-end justify-content-md-center"
					>
						<Rating
							class="w-50"
							:starSize="20"
							:defaultValue="questionAverage(question.id)"
							:readonly="true"
							:roundStartRating="false"
						/>
					</div>
				</div>
			</div>
		</div>

		<div
			class="mt-5 w-100 rounded d-flex align-items-center justify-content-center p-4 improvement-plan"
		>
			<Button
				@click="changeCurrentDashboardTab(2)"
				label="Iniciar plan de mejora"
				class="bg-yellow"
			></Button>
		</div>

		<!-- <TotalAndSearch
			class="mt-5"
			:title="'Feedback pendientes'"
			:showActionButton="false"
		/>

		<div class="row mt-3">
			<div class="col-12 col-md-6 col-lg-4">
				<UserCard :showOverlay="false" :showStatus="false">
					<template #action>
						<div
							class="d-flex align-items-center justify-content-center flex-wrap gap-2"
						>
							<Button label="Dar feedback" class="bg-primary text-white" />
							<Button
								@click="seeFeedback"
								label="Ver feedback"
								class="bg-primary text-white"
							/>
						</div>
					</template>
				</UserCard>
			</div>
		</div> -->
	</div>
</template>

<script>
	import Card from "primevue/card";

	import Rating from "@/components/user/partials/Rating.vue";
	// import UserCard from "@/components/user/partials/UserCard.vue";
	import TotalAndSearch from "@/components/user/partials/TotalAndSearch.vue";
	import FeedbackDialog from "@/components/user/partials/dialogs/FeedbackDialog.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Card,
			// UserCard,
			FeedbackDialog,
			TotalAndSearch,
			Rating,
		},
		data() {
			return {
				search: null,
				sectionAverages: [],
				questionAverages: [],
				totalAverage: 0,
			};
		},
		mounted() {
			this.toggleLoading(true);

			this.getFeedbackQuestionAnswers().then(() => {
				this.calculateAverages();
				this.toggleLoading(false);
			});

			var accordion = document.getElementsByClassName("accordion");

			for (let i = 0; i < accordion.length; i++) {
				accordion[i].addEventListener("click", function () {
					this.classList.toggle("active");

					const panel = this.nextElementSibling;

					if (panel.style.maxHeight) {
						panel.style.maxHeight = null;
					} else {
						panel.style.maxHeight = panel.scrollHeight + "px";
					}
				});
			}
		},
		methods: {
			...mapActions(["getFeedbackQuestionAnswers"]),
			...mapMutations([
				"changeCurrentDashboardTab",
				"toggleFormDialog",
				"toggleLoading",
			]),
			seeFeedback() {
				this.toggleFormDialog({
					stateVariable: "user_feedback",
					show: true,
				});
			},
			questions(index) {
				switch (index) {
					case 0:
						return this.startFeedback.communicationQuestions;

					case 1:
						return this.startFeedback.behaviorQuestions;

					case 2:
						return this.startFeedback.performanceQuestions;
				}
			},
			calculateAverages() {
				this.sectionAverages = [];
				this.questionAverages = [];

				this.employee.feedbackQuestionAnswers.forEach((questionAnswer) => {
					if (
						!this.questionAverages.find(
							(question) => question.id === questionAnswer.question_id
						)
					) {
						this.questionAverages.push({
							id: questionAnswer.question_id,
							average: questionAnswer.answer,
							count: 1,
						});
					} else {
						let question = this.questionAverages.find(
							(question) => question.id === questionAnswer.question_id
						);

						question.average =
							(question.average * question.count + questionAnswer.answer) /
							(question.count + 1);

						question.count++;
					}

					if (
						!this.sectionAverages.find(
							(section) => section.id === questionAnswer.question.section_id
						)
					) {
						this.sectionAverages.push({
							id: questionAnswer.question.section_id,
							average: questionAnswer.answer,
							count: 1,
						});
					} else {
						let section = this.sectionAverages.find(
							(section) => section.id === questionAnswer.question.section_id
						);

						section.average =
							(section.average * section.count + questionAnswer.answer) /
							(section.count + 1);

						section.count++;
					}
				});

				this.sectionAverages.forEach((average) => {
					this.totalAverage += average.average;
				});

				this.totalAverage = this.totalAverage / this.sectionAverages.length;
			},
			questionAverage(question_id) {
				let questionAverage = null;

				this.questionAverages.forEach((average) => {
					if (average.id == question_id) {
						questionAverage = average.average;
					}
				});

				return questionAverage;
			},
			sectionAverage(section_id) {
				let sectionAverage = null;

				this.sectionAverages.forEach((average) => {
					if (average.id == section_id) {
						sectionAverage = average.average;
					}
				});

				return sectionAverage;
			},
		},
		computed: {
			...mapState(["startFeedback", "employee"]),
		},
	};
</script>

<style lang="scss" scoped>
	.accordion {
		transition: $transition;
	}

	.panel {
		max-height: 0;
		overflow: hidden;
		transition: $transition;
	}

	.improvement-plan {
		background-image: url("@/assets/images/misc/background-1.jpg");
		background-position: center;
		background-size: cover;
		position: relative;
		overflow: hidden;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($primary, 0.5);
		}
	}
</style>
