import { createWebHistory, createRouter } from "vue-router";

import axios from "./axios";
import store from "./store";

// Layouts
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import AdminLayout from "@/components/layouts/AdminLayout.vue";
import UserLayout from "@/components/layouts/UserLayout.vue";

// Admin
import Customers from "@/components/admin/customers/Customers.vue";
import Users from "@/components/admin/users/Users.vue";
import Library from "@/components/admin/libraries/Library.vue";
// import Roles from "@/components/admin/roles/Roles.vue";
// import Demo from "@/components/admin/demo/Demo.vue";

// User
import UserDashboard from "@/components/user/dashboard/Dashboard.vue";

const routes = [
	{
		path: "/login",
		component: AuthLayout,
		beforeEnter: checkLogin,
		name: "login",
	},
	{
		path: "/password/reset/:token",
		component: AuthLayout,
		name: "PasswordReset",
	},
	{
		path: "/admin",
		component: AdminLayout,
		beforeEnter: checkUserLogged,
		children: [
			{
				path: "customers",
				name: "Clientes",
				component: Customers,
			},
			{
				path: "users",
				name: "Usuarios",
				component: Users,
			},
			{
				path: "libraries",
				name: "Biblioteca",
				component: Library,
			},
			// {
			// 	path: "roles",
			// 	name: "Admin - Roles",
			// 	component: Roles,
			// },
			// {
			// 	path: "demo",
			// 	name: "Admin - Demo",
			// 	component: Demo,
			// },
		],
	},
	{
		path: "/user",
		component: UserLayout,
		beforeEnter: checkUserLogged,
		children: [
			{
				path: "dashboard",
				component: UserDashboard,
			},
		],
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/login",
	},
];

function checkLogin(to, from, next) {
	if (localStorage.getItem("accessToken") != null) {
		let user = null;

		if (localStorage.getItem("userLogged") != null) {
			user = JSON.parse(localStorage.getItem("userLogged"));
		}

		if (user?.role?.name == "super_admin" || user?.role?.name == "admin") {
			next({ path: "/admin/customers" });
		} else {
			next({ path: "/user/dashboard" });
		}
	} else {
		next();
	}

	return;
}

function checkUserLogged(to, from, next) {
	if (localStorage.getItem("accessToken") == null) {
		next({ path: "/login" });

		return;
	}

	axios.defaults.headers.common[
		"Authorization"
	] = `Bearer ${localStorage.getItem("accessToken")}`;

	axios
		.post("/check-user-logged")
		.then((response) => {
			store.commit("changeUserLogged", response.data.user);

			if (to.path === "/login") {
				if (
					response.data.user?.role?.name == "super_admin" ||
					response.data.user?.role?.name == "admin"
				) {
					next({ path: "/admin/customers" });
				} else {
					next({ path: "/user/dashboard" });
				}
			} else {
				if (
					to.path.includes("/admin") &&
					(response.data.user?.role?.name == "manager" ||
						response.data.user?.role?.name == "empleado" ||
						response.data.user?.role?.name == "invitado")
				) {
					next({ path: "/user/dashboard" });
				} else {
					next();
				}
			}
		})
		.catch((error) => {
			console.error(
				"Router JS ~ checkAdminRights",
				error.response,
				error.response?.data?.message
			);

			if (error.response.data.message === "CSRF token mismatch.") {
				location.reload();
			}

			if (
				error.response.data.message == "User not found" ||
				error.response.data.message == "Unauthorized" ||
				error.response.data.message == "Unauthenticated."
			) {
				window.localStorage.clear();
				next({ path: "/login" });
			}
		});

	return;
}

const router = new createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
