<template>
	<div class="library-element rounded box-shadow bg-white">
		<div class="image">
			<img :src="image" />
		</div>
		<div class="content d-flex flex-column justify-content-between p-3">
			<div>
				<h3 class="mt-3 text-primary">{{ title }}</h3>
				<div class="col-6 col-md-4">
					<hr class="bg-primary" />
				</div>
				<p
					class="text-overflow text-primary"
					style="white-space: pre-line"
					v-html="description"
				></p>
			</div>
			<div class="d-flex">
				<div
					v-if="link == null"
					class="download bg-lightgray text-white rounded small px-2 py-1"
					@click="
						downloadFile({
							downloadRoute: 'libraries/download',
							fileName: file,
							folder: 'libraries/files',
							downloadFileName: 'Archivo',
						})
					"
				>
					Más información
				</div>
				<a
					v-else
					:href="link"
					target="_blank"
					class="link text-gray small cursor-pointer"
					>Más información</a
				>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions } from "vuex";

	export default {
		props: {
			image: {
				type: String,
				required: true,
			},
			title: {
				type: String,
				required: true,
			},
			description: {
				type: String,
				required: true,
			},
			link: {
				type: String,
				required: false,
				default: null,
			},
			file: {
				type: String,
				required: false,
				default: null,
			},
		},
		methods: {
			...mapActions(["downloadFile"]),
		},
	};
</script>

<style lang="scss" scoped>
	.library-element {
		aspect-ratio: 9/10;
		box-shadow: $smooth-box-shadow;
		overflow: hidden;
		transition: $transition;

		&:hover {
			transform: translateY(3px);
		}

		.image {
			height: 35%;
			width: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.content {
			height: 65%;

			.text-overflow {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
			}

			.download {
				cursor: pointer;
				transition: $transition;

				&:hover {
					background-color: $primary !important;
				}
			}

			.link {
				text-decoration: none;
			}
		}

		// Mobiles
		@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.content {
				.text-overflow {
					-webkit-line-clamp: 4;
				}
			}
		}
	}
</style>
