<template>
	<Dialog
		v-model:visible="$store.state.libraries.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form>
			<div class="row gy-3">
				<div class="col-12">
					<FileUpload
						label="Imagen"
						ref="image"
						:error="form.errors.get('image')"
						:disabled="disabled"
						:accept="'image/*'"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="title"
						label="Título"
						:disabled="disabled"
						:error="form.errors.get('title')"
						@change-value="(value) => (form.title = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="description"
						label="Descripción"
						:disabled="disabled"
						:error="form.errors.get('description')"
						@change-value="(value) => (form.description = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<SelectButton
						ref="type"
						label="Tipo"
						:disabled="disabled"
						:error="form.errors.get('type')"
						@change-value="(value) => (form.type = value)"
						:values="[
							{ value: 'link', label: 'Enlace' },
							{ value: 'file', label: 'Archivo' },
						]"
						:multiple="false"
					/>
				</div>
				<div class="col-12 col-md-6"></div>
				<div v-if="form.type == 'link'" class="col-12 col-md-6">
					<InputText
						ref="link"
						label="Enlace"
						:disabled="disabled"
						:error="form.errors.get('link')"
						@change-value="(value) => (form.link = value)"
					/>
				</div>
				<div v-if="form.type == 'file'" class="col-12">
					<FileUpload
						label="Archivo"
						ref="file"
						:error="form.errors.get('file')"
						:disabled="disabled"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-end">
				<Button @click="save()" label="Enviar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Dialog from "primevue/dialog";
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Dialog,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "registro",
			title: `Añadir registro`,
			disabled: false,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.libraries.register != null;

				const url = `/${this.route}${
					update ? `/${this.libraries.register.id}` : ""
				}`;

				this.form.image = this.$refs.image.files;

				if (this.$refs.file != undefined) {
					this.form.file = this.$refs.file.files;
				} else {
					this.form.file = null;
				}

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.libraries.currentPage,
							rows: this.libraries.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (
						Object.hasOwnProperty.call(this.$refs, key) &&
						this.$refs[key] != undefined
					) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.libraries.register;

				if (register != null) {
					for (const key in register) {
						if (Object.hasOwnProperty.call(register, key)) {
							if (this.$refs[key] != undefined) {
								this.$refs[key].model = register[key];
							}
						}
					}

					this.$refs.type.setValue(register.link != null ? "link" : "file");

					this.$nextTick(() => {
						if (register.link != null) {
							this.$refs.link.model = register.link;
						}
					});

					if (this.libraries.dialogMode == "edit") {
						this.title = `Editar ${this.modelName}`;
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "libraries"]),
		},
	};
</script>
