import Form from "vform";
import http from "../axios";
import { func } from "../helpers";

const actions = {
	// Auth actions
	async login({ state }, { form, errors }) {
		try {
			const response = await form.post(state.baseURL + "/login");

			localStorage.setItem("accessToken", response.data.token);
			localStorage.setItem("userLogged", JSON.stringify(response.data.user));

			if (
				response.data.user.role.name == "super_admin" ||
				response.data.user.role.name == "admin"
			) {
				window.location.href = "/admin/customers";
			} else {
				window.location.href = "/user/dashboard";
			}
		} catch (error) {
			if (error.response?.data?.message) {
				errors.set("email", error.response.data.message);
			}
		}
	},
	setFormAccessToken() {
		const instance = http.create({
			headers: {
				Authorization: "Bearer " + localStorage.getItem("accessToken"),
			},
		});

		Form.axios = instance;
	},
	logout() {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("userLogged");

		window.location.href = "/login";
	},

	// Helper actions
	copyToClipboard({ state }, data) {
		try {
			navigator.clipboard.writeText(data);
			state.successToast = "Texto copiado al portapapeles";
		} catch (error) {
			state.errorToast = "Error al copiar al portapapeles";
		}
	},
	async downloadFile({ state }, params) {
		try {
			const response = await http({
				method: "post",
				url: params.downloadRoute,
				params: {
					folder: params.folder,
					fileName: params.fileName,
					databaseFileColumn: params.databaseFileColumn,
					databaseFileNameColumn: params.databaseFileNameColumn,
				},
				responseType: !params.isFromDatabase ? "blob" : "",
			});

			let link = null;

			if (params.isFromDatabase) {
				link = document.createElement("a");
				link.href = response.data.fileData;

				link.setAttribute("download", response.data.fileName);

				document.body.appendChild(link);
				link.click();
			} else {
				const fileName =
					params.downloadFileName != null
						? `${params.downloadFileName}.${params.fileName.split(".")[1]}`
						: params.fileName;

				const url = window.URL.createObjectURL(new Blob([response.data]));

				link = document.createElement("a");
				link.href = url;

				link.setAttribute("download", fileName);

				document.body.appendChild(link);
				link.click();
			}

			state.successToast = "Archivo descargado correctamente";

			if (link != null) {
				link.parentNode.removeChild(link);
			}
		} catch (error) {
			console.error(error);
			state.errorToast = true;

			return error;
		}
	},

	// Registers functions
	async sendForm({ state }, { method = "post", form, url, errors }) {
		try {
			let response;

			state.loading = true;

			if (method == "post") {
				response = await form.post(state.baseURL + url);
			} else if (method == "put") {
				response = await form.put(state.baseURL + url);
			}

			state.successToast = "Registro guardado correctamente";
			state.loading = false;
			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			if (error.response?.data) {
				for (const key in error.response.data) {
					errors.set(key, error.response.data[key]);
				}
			}

			return error.response;
		}
	},
	async deleteRegisters({ state }, { url, ids = null }) {
		try {
			state.loading = true;

			const response = await http({
				method: "delete",
				url: url,
				data: {
					ids,
				},
			});

			state.loading = false;
			state.successToast = "Registros borrados correctamente";

			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			return error.response;
		}
	},
	async getRegisters({ state }, params) {
		try {
			if (
				params.showLoading == null ||
				params.showLoading == undefined ||
				params.showLoading
			) {
				state.datatableDefaults.loading = true;
			}

			let formProps = func.formatFilters("filters");

			let httpParams = {
				...formProps,
			};

			if (
				params.getAll == undefined ||
				(params.getAll != undefined && !params.getAll)
			) {
				httpParams.pagination = {
					currentPage: params?.page != null ? params.page : 0,
					rows:
						params?.rows != null ? params.rows : state.datatableDefaults.rows,
				};
			}

			if (params.search != undefined) {
				httpParams.search = params.search;
			}

			if (params.additionalParams != undefined) {
				httpParams = {
					...httpParams,
					...params.additionalParams,
				};
			}

			const response = await http({
				url: params.route,
				params: httpParams,
			});

			state.datatableDefaults.loading = false;

			if (!params.concatList) {
				state[params.stateVariable].list = response.data[params.stateVariable];
			} else {
				state[params.stateVariable].list = [
					...state[params.stateVariable].list,
					...response.data[params.stateVariable],
				];
			}

			state[params.stateVariable].listTotal = response.data.total;
		} catch (error) {
			console.error(error);

			if (error.response.data.message === "CSRF token mismatch.") {
				location.reload();
			}

			if (
				error.response.data?.message == "User not found" ||
				error.response.data?.message == "Unauthorized" ||
				error.response.data?.message == "Unauthenticated."
			) {
				window.localStorage.clear();
				window.location.href = "/login";
			}

			state.showFormGeneralErrorToast = true;

			return error;
		}
	},

	// Manager
	async updateGuestStatus({ state }, params) {
		try {
			state.loading = true;

			const response = await http({
				method: "post",
				url: "/guests/update-status",
				data: {
					id: params.id,
					status: params.status,
				},
			});

			state.loading = false;
			state.successToast = "Estado del invitado actualizado";

			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			return error.response;
		}
	},

	// Employees
	async getEmployeeResponsible({ state }) {
		try {
			const response = await http({
				url: "/employee-responsible",
			});

			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			return error.response;
		}
	},
	async getImprovementPlans({ state }) {
		try {
			const response = await http({
				url: "/wants-to-improve",
			});

			state.employee.wantsToImproves = response.data.wantsToImproves;

			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			return error.response;
		}
	},

	// Guests
	async feedbackSections({ state }) {
		try {
			const response = await http({
				url: "/feedback-sections",
			});

			state.startFeedback.communicationQuestions = response.data.sections[1];
			state.startFeedback.behaviorQuestions = response.data.sections[2];
			state.startFeedback.performanceQuestions = response.data.sections[3];

			state.startFeedback.sectionsNames = response.data.sectionsNames;

			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			return error.response;
		}
	},
	async sendFeedback({ state }) {
		try {
			const response = await http({
				url: "/send-feedback",
				method: "post",
				data: {
					...state.startFeedback,
					selectedEmployee: state.guest.selectedEmployee
				},
			});

			state.successToast = "Feedback enviado";

			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			return error.response;
		}
	},
	async getFeedbackQuestionAnswers({ state }) {
		try {
			const response = await http({
				url: "/feedback-question-answers",
				params: {
					selectedEmployee: state.manager.selectedEmployee?.user.id
				}
			});

			state.employee.feedbackQuestionAnswers = response.data.feedbackQuestionAnswers;
			state.employee.totalFeedbacks = response.data.totalFeedbacks;

			return response;
		} catch (error) {
			console.error(error);

			state.loading = false;

			if (error.response?.data?.message) {
				state.errorToast = error.response.data.message;
			}

			return error.response;
		}
	},
};

export default actions;
