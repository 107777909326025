<template>
	<div
		class="cursor-pointer close-session d-flex align-items-center justify-content-center gap-2 p-ripple"
		v-ripple
		@click="logout()"
	>
		<i class="pi pi-power-off"></i>
		Cerrar sesión
	</div>
</template>

<script>
	import { mapActions } from "vuex";
	export default {
		methods: {
			...mapActions(["logout"]),
		},
	};
</script>

<style lang="scss" scoped>
	.close-session {
		background-color: white;
		color: #666;
		position: relative;
		transition: $transition;
		height: 100%;

		&:hover {
			background-color: $primary;
			color: white;

			&::after {
				opacity: 0;
			}
		}
	}

	@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.close-session {
			height: 3rem;
		}
	}
</style>
