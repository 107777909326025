<template>
	<div>
		<PendingFeedbacks />
		<CompletedFeedbacks class="mt-4" />
	</div>
</template>

<script>
	import PendingFeedbacks from "@/components/user/feedback/guest_feedback/PendingFeedbacks.vue";
	import CompletedFeedbacks from "@/components/user/feedback/guest_feedback/CompletedFeedbacks.vue";

	export default {
		components: {
			PendingFeedbacks,
			CompletedFeedbacks,
		},
	};
</script>
