<template>
	<div v-if="data != null">
		<div class="image rounded overflow-hidden">
			<img :src="data" />
		</div>
	</div>
	<div v-else>Sin información</div>
</template>

<script>
	export default {
		props: {
			data: {
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.image {
		max-width: 100px;
		max-height: 70px;

		img {
			width: 100%;
		}
	}
</style>
