<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-primary text-center title mb-3 mb-md-1">
				Bienvenido/a a Mrs. Wolf
			</div>
			<div
				class="col-12 text-center d-flex justify-content-start justify-content-md-center gap-2"
			>
				<p class="text-muted">¿No tienes cuenta?</p>
				<p
					@click="$parent.showForm = 'register'"
					class="cursor-pointer text-yellow"
				>
					Crea una ahora
				</p>
			</div>
			<div class="col-12">
				<InputText
					label="Email"
					:error="form.errors.get('email')"
					@change-value="(value) => (form.email = value)"
					:type="'email'"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Contraseña"
					:error="form.errors.get('password')"
					:feedback="false"
					@change-value="(value) => (form.password = value)"
				/>
				<p
					@click="$parent.showForm = 'forgot'"
					class="text-yellow cursor-pointer mt-2 mb-1"
				>
					Olvidé mi contraseña
				</p>
			</div>
			<div class="col-12 d-flex justify-content-center mt-3 mt-md-4">
				<Button
					:disabled="form.busy"
					@click="save()"
					label="Iniciar sesión"
					class="bg-salmon"
				/>
			</div>
		</div>
	</form>
</template>

<script>
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		data: () => ({
			form: new Form(),
			showForgotPassword: false,
			showRegister: false,
		}),
		methods: {
			...mapActions(["login"]),
			save() {
				this.login({
					form: this.form,
					errors: this.form.errors,
				});
			},
		},
	};
</script>
