<template>
	<div>
		<label for="input" class="text-primary text-bold">{{ label }}</label>

		<Dropdown
			v-model="model"
			:options="options"
			:filter="filter"
			:showClear="showClear"
			:placeholder="label"
			:disabled="disabled || options.length == 0"
			class="w-100"
			:class="[error != null ? 'p-invalid' : '']"
		>
			<template #value="slotProps">
				<div v-if="slotProps.value">
					{{ formatText(slotProps.value) }}
				</div>
				<span v-else>
					{{ slotProps.placeholder }}
				</span>
			</template>
			<template #option="slotProps">
				{{ formatText(slotProps.option) }}
			</template>
		</Dropdown>

		<div v-if="error != null" class="text-danger">
			{{ error }}
		</div>
	</div>
</template>

<script>
	import Dropdown from "primevue/dropdown";

	export default {
		components: {
			Dropdown,
		},
		props: {
			label: {
				type: String,
				required: true,
			},
			options: {
				type: Array,
				required: true,
			},
			optionValue: {
				type: String,
				default: "id",
			},
			filter: {
				type: Boolean,
				default: true,
			},
			showClear: {
				type: Boolean,
				default: true,
			},
			error: {
				type: String,
				default: null,
			},
			displayText: {
				type: [String, Array],
				required: true,
			},
			displayTextSeparator: {
				type: String,
				default: " ",
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		data: () => ({
			model: null,
		}),
		methods: {
			formatText(option) {
				if (Array.isArray(this.displayText)) {
					let text = "";

					this.displayText.forEach((item, index) => {
						if (item.includes(".")) {
							let parts = item.split(".");

							if (option[parts[0]] != null) {
								text += option[parts[0]][parts[1]];
							}
						} else {
							text += option[item];
						}

						if (text != "" && index < this.displayText.length - 1) {
							text += this.displayTextSeparator;
						}
					});

					return text;
				} else {
					return option[this.displayText];
				}
			},
			setValue(value) {
				if (value != null) {
					this.model = this.options.find(
						(option) => option[this.optionValue] == value
					);
				} else {
					this.model = null;
				}
			}
		},
		watch: {
			model(newValue) {
				if (newValue != null) {
					this.$emit("change-value", newValue[this.optionValue]);
				} else {
					this.$emit("change-value", null);
				}
			},
		},
	};
</script>
