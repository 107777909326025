<template>
	<Dialog
		v-model:visible="$store.state.user_improvement_plans.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<div class="d-flex align-items-center gap-3">
				<img src="@/assets/images/forms/improvement-plan.png" width="50" />
				<FormTitle :title="title" :class="'text-white'" />
			</div>
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 mt-2">
				<div class="col-12">
					<h3 class="initial-font text-muted">Áreas de mejora</h3>
					<p class="text-primary mb-0">
						Selecciona un área de mejora y explica el por qué quieres mejorarla.
					</p>
				</div>
				<div v-if="form.errors.get('option') != null" class="text-danger">
					Debe escoger una opción entre todos los cuadros
				</div>
				<div class="col-12">
					<SelectButton
						class="select-button"
						ref="comunication"
						label="Comunicación"
						:disabled="disabled"
						@change-value="(value) => (form.comunication = value)"
						:multiple="false"
						:values="communicationOptions"
					/>
				</div>
				<div class="col-12">
					<SelectButton
						class="select-button"
						ref="behavior"
						label="Comportamiento"
						:disabled="disabled"
						@change-value="(value) => (form.behavior = value)"
						:multiple="false"
						:values="behaviorOptions"
					/>
				</div>
				<div class="col-12">
					<SelectButton
						class="select-button"
						ref="performance"
						label="Rendimiento"
						:disabled="disabled"
						@change-value="(value) => (form.performance = value)"
						:multiple="false"
						:values="performanceOptions"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="reason"
						label="¿Por qué?"
						:placeholder="'Explica por qué quieres mejorar este área.'"
						:disabled="disabled"
						:error="form.errors.get('reason')"
						@change-value="(value) => (form.reason = value)"
					/>
				</div>
				<div class="col-12">
					<hr class="mb-0" />
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-center">
				<Button
					@click="save()"
					label="Enviar"
					class="bg-yellow"
					:disabled="form.busy"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Dialog from "primevue/dialog";

	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Dialog,
		},
		props: {
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "plan de mejora",
			title: `Añadir plan de mejora`,
			disabled: false,
			communicationOptions: [
				{ value: "effective-communication", label: "Comunicación efectiva" },
				{ value: "honest-communication", label: "Comunicación honesta" },
				{ value: "decision-making", label: "Toma de decisiones" },
				{ value: "assertiveness", label: "Asertividad" },
				{ value: "conflict-management", label: "Gestión de conflictos" },
			],
			behaviorOptions: [
				{ value: "diversity-management", label: "Gestion de la diversidad" },
				{
					value: "builds-interdepartmental-relationships",
					label: "Construye relaciones interdepartamentales",
				},
				{ value: "assertiveness", label: "Asertividad" },
				{ value: "collaboration-with-others", label: "Colaboracion con otros" },
				{
					value: "generates-confidence-around-you",
					label: "Genera confianza a su alrededor",
				},
				{ value: "executive-presence", label: "Presencia ejecutiva" },
				{ value: "self-confidence", label: "Autoconfianza" },
				{ value: "leads-change", label: "Lidera cambio" },
				{ value: "coaching-and-mentoring", label: "Coaching y mentoring" },
			],
			performanceOptions: [
				{ value: "delegates-effectively", label: "Delega eficazmente" },
				{ value: "empower-others", label: "Empodera a los demás" },
				{ value: "performance-orientation", label: "Orientación a resultados" },
				{ value: "strategic-thinking", label: "Pensamiento estratégico" },
				{ value: "he-is-an-entrepreneur", label: "Es emprendedor" },
				{ value: "take-calculated-risks", label: "Corre riesgos calculados" },
				{
					value: "promotes-responsibility",
					label: "Fomenta la responsabilidad",
				},
				{
					value: "manage-performance-issues-in-a-timely-manner",
					label: "Gestiona a tiempo los problemas de rendimiento",
				},
			],
		}),
		methods: {
			...mapActions(["getUsers", "sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const url = `/improvement-plan`;

				if (this.form.performance != null) {
					this.form.option = this.form.performance;
				} else if (this.form.behavior != null) {
					this.form.option = this.form.behavior;
				} else if (this.form.comunication != null) {
					this.form.option = this.form.comunication;
				}

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.user_improvement_plans.register?.user;

				if (register != null) {
					for (const key in register) {
						if (Object.hasOwnProperty.call(register, key)) {
							if (this.$refs[key] != undefined) {
								this.$refs[key].model = register[key];
							}
						}
					}

					if (this.user_improvement_plans.dialogMode == "edit") {
						this.title = `Editar ${this.modelName}`;
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "user_improvement_plans"]),
		},
		watch: {
			"form.comunication": function (newValue) {
				if (newValue != null) {
					this.$refs["behavior"].model = null;
					this.$refs["performance"].model = null;
				}
			},
			"form.behavior": function (newValue) {
				if (newValue != null) {
					this.$refs["comunication"].model = null;
					this.$refs["performance"].model = null;
				}
			},
			"form.performance": function (newValue) {
				if (newValue != null) {
					this.$refs["comunication"].model = null;
					this.$refs["behavior"].model = null;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.select-button {
		border-radius: 1rem;
		border: 1px solid #e0e0e0;
		overflow: hidden;

		:deep(label) {
			font-size: 1.2rem;
			font-weight: 500;
			padding: 0.75rem 1.25rem;
		}

		:deep(.p-selectbutton) {
			display: flex;
			flex-direction: column;

			.p-button {
				text-align: left;
				border-radius: 0;
				border: none !important;
				border-top: 1px solid #e0e0e0 !important;
				color: $primary;

				&.p-highlight {
					background-color: $green;
					color: white;

					.p-button-label {
						font-weight: bold !important;
					}
				}

				.p-button-label {
					font-weight: normal;
				}
			}
		}
	}
</style>
