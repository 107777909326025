<template>
	<swiper
		class="col-11 col-md-9"
		@slideChange="onSlideChange"
		:slides-per-view="1"
		:pagination="pagination"
		:modules="modules"
		:keyboard="true"
		@swiper="onSwiper"
	>
		<swiper-slide v-for="(ratingSlide, index) in ratings" :key="index">
			<div class="d-flex flex-column">
				<h4 class="text-white">{{ ratingSlide.question }}</h4>
				<p class="text-white">
					{{ ratingSlide.text }}
				</p>
				<Rating
					class="mx-auto w-75 mt-3"
					:starSize="50"
					@change-value="(value) => updateRatingBehavior(value, index)"
				/>
			</div>
		</swiper-slide>
		<swiper-slide>
			<div class="d-flex flex-column">
				<h1 class="text-white mb-2 mb-md-5">Primera sección completada</h1>
				<h4 class="text-white">Estas son tus puntuaciones:</h4>
				<div
					v-for="(ratingSlide, index) in ratings"
					:key="index"
					class="d-flex align-items-center justify-content-between mb-2"
				>
					<div class="col-8">
						<p class="text-white mb-0">{{ ratingSlide.question }}</p>
					</div>
					<div class="col-4 d-flex justify-content-end">
						<Rating
							:ref="`RatingBehavior${index + 1}`"
							class="w-100"
							:starSize="20"
							:starColor="'#0b3e00'"
							:inactiveColor="'#49773e'"
							:readonly="true"
						/>
					</div>
				</div>
				<div class="d-flex align-items-center justify-content-between mt-4">
					<div class="col-8">
						<p class="text-white mb-0">Puntuación media</p>
					</div>
					<div class="col-4 d-flex justify-content-end">
						<Rating
							ref="AverageRating"
							class="w-100"
							:starSize="20"
							:inactiveColor="'#49773e'"
							:readonly="true"
							:roundStartRating="false"
						/>
					</div>
				</div>
			</div>
		</swiper-slide>
	</swiper>
</template>

<script>
	import { getCurrentInstance } from "vue";

	import { Pagination, Keyboard } from "swiper";
	import { Swiper, SwiperSlide } from "swiper/vue";

	import "swiper/css";
	import "swiper/css/pagination";
	import "swiper/css/keyboard";

	import Rating from "@/components/user/partials/Rating.vue";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			Swiper,
			SwiperSlide,
			Rating,
		},
		data() {
			return {
				pagination: {
					el: ".swiper-scrollbar",
					type: "progressbar",
				},
				ratings: [],
				averageRating: 0,
				swiper: null,
			};
		},
		setup() {
			const instance = getCurrentInstance();

			const onSwiper = (swiper) => {
				instance.data.swiper = swiper;
			};

			return {
				onSwiper,
				modules: [Pagination, Keyboard],
			};
		},
		methods: {
			...mapMutations(["changeBehaviorRatings"]),
			onSlideChange($e) {
				this.$parent.activeIndex = $e.activeIndex;
			},
			updateRatingBehavior(value, ref) {
				this.$refs["RatingBehavior" + (ref + 1)][0].value = value;
				this.ratings[ref].value = value;
				this.calculateAverage();

				this.changeBehaviorRatings(this.ratings);
			},
			calculateAverage() {
				let sum = 0;

				this.ratings.forEach((rating, index) => {
					sum += this.$refs["RatingBehavior" + (index + 1)][0].value;
				});

				this.averageRating = sum / this.ratings.length;
			},
			goPreviousSlide() {
				this.swiper.slidePrev();
			},
			goNextSlide() {
				this.swiper.slideNext();
			},
			goFirstSlide() {
				this.swiper.slideTo(0);
			},
			getCurrentSlide() {
				return this.swiper.activeIndex;
			},
		},
		computed: {
			...mapState(["startFeedback"]),
		},
		watch: {
			averageRating() {
				this.$refs.AverageRating.value = this.averageRating;
			},
			"startFeedback.currentSlide": function () {
				this.swiper.slideTo(0);
			},
		},
		mounted() {
			this.changeBehaviorRatings(this.ratings);

			this.startFeedback.behaviorQuestions.forEach((rating) => {
				this.ratings.push({
					question: rating.question,
					text: rating.description,
					value: 0,
					questionId: rating.id,
				});
			});
		},
	};
</script>
