<template>
	<div>
		<TableDefault
			:route="route"
			:stateVariable="stateVariable"
			:list="libraries.list"
			:total="libraries.listTotal"
			:filters="filters"
			:delete="'libraries'"
		>
			<template #columns>
				<Column header="Imagen">
					<template #body="slotProps">
						<ImageColumn :data="slotProps.data.imageBase64" />
					</template>
				</Column>
				<Column header="Título" field="title"></Column>
				<Column header="Descripción">
					<template #body="slotProps">
						<LongTextColumn :data="slotProps.data.description" />
					</template>
				</Column>
				<Column header="Enlace">
					<template #body="slotProps">
						<div v-if="slotProps.data.link != null">
							<LongTextColumn :data="slotProps.data.link" />
						</div>
						<div v-else class="text-muted">Sin enlace</div>
					</template>
				</Column>
				<Column header="Archivo">
					<template #body="slotProps">
						<DownloadFileColumn
							:downloadRoute="'libraries/download'"
							:fileName="slotProps.data.file"
							:folder="'libraries/files'"
							:downloadFileName="'Archivo'"
						/>
					</template>
				</Column>
			</template>
		</TableDefault>
	</div>
</template>

<script>
	import Column from "primevue/column";
	import { mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "title",
						value: null,
						type: "string",
						placeholder: "Título",
					},
					{
						name: "description",
						value: null,
						type: "string",
						placeholder: "Descripción",
					},
					{
						name: "link",
						value: null,
						type: "string",
						placeholder: "Enlace",
					},
				],
			};
		},
		computed: {
			...mapState(["libraries", "userLogged"]),
		},
	};
</script>
