<template>
	<div class="content col-11 col-md-9">
		<div class="d-flex flex-column">
			<h1 class="text-white mb-2 mb-md-5">
				Has completado todas las secciones
			</h1>
			<h4 class="text-white mb-2 mb-md-3">Estas son tus puntuaciones:</h4>

			<Accordion
				v-for="(slide, index) in slides"
				:key="index"
				@toggleActive="toggleActive(`${slide.name}Accordion`)"
				:ref="`${slide.name}Accordion`"
			>
				<template #header>
					<div
						class="d-flex align-items-center justify-content-between mb-2"
						:class="{ 'mt-3': index != 0 }"
					>
						<div class="col-8">
							<p class="text-white mb-0">
								<b>{{ slide.title }}</b>
							</p>
						</div>
						<div
							class="col-4"
							:set="
								(average = calculateAverage(startFeedback[slide.stateVariable]))
							"
						>
							<Rating
								class="w-100"
								:starSize="20"
								:inactiveColor="'#49773e'"
								:readonly="true"
								:defaultValue="average"
								:roundStartRating="false"
								v-tooltip.right="`Puntuación media: ${average}`"
							/>
						</div>
					</div>
				</template>
				<template #body>
					<div
						v-for="(rating, index) in startFeedback[slide.stateVariable]"
						:key="index"
						class="d-flex align-items-center justify-content-between mb-2"
					>
						<div class="col-8">
							<p class="text-white mb-0">{{ rating.question }}</p>
						</div>
						<div class="col-4">
							<Rating
								class="w-100"
								:starSize="20"
								:starColor="'#0b3e00'"
								:inactiveColor="'#49773e'"
								:readonly="true"
								:defaultValue="rating.value"
							/>
						</div>
					</div>
				</template>
			</Accordion>

			<div class="d-flex align-items-center justify-content-between mt-4">
				<div class="col-8">
					<p class="text-white mb-0"><b>Puntuación total</b></p>
				</div>
				<div class="col-4">
					<Rating
						:starSize="20"
						:inactiveColor="'#49773e'"
						:readonly="true"
						:defaultValue="fullAverage"
						:roundStartRating="false"
						v-tooltip.right="`Puntuación total: ${fullAverage}`"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Rating from "@/components/user/partials/Rating.vue";
	import Accordion from "@/components/user/partials/Accordion.vue";
	import { mapState } from "vuex";

	export default {
		components: {
			Rating,
			Accordion,
		},
		data() {
			return {
				slides: [
					{
						name: "Communication",
						title: "Comunicación",
						stateVariable: "communicationRatings",
					},
					{
						name: "Behavior",
						title: "Comportamiento",
						stateVariable: "behaviorRatings",
					},
					{
						name: "Performance",
						title: "Rendimiento",
						stateVariable: "performanceRatings",
					},
				],
				fullAverage: 0,
			};
		},
		methods: {
			toggleActive(ref) {
				if (ref != "CommunicationAccordion") {
					this.$refs.CommunicationAccordion[0].active = false;
				}

				if (ref != "BehaviorAccordion") {
					this.$refs.BehaviorAccordion[0].active = false;
				}

				if (ref != "PerformanceAccordion") {
					this.$refs.PerformanceAccordion[0].active = false;
				}
			},
			calculateAverage(ratings) {
				let sum = 0;

				ratings.forEach((rating) => {
					sum += rating.value;
				});

				return sum / ratings.length;
			},
			calculateFullAverage() {
				let sum = 0;

				this.slides.forEach((slide) => {
					sum += this.calculateAverage(this.startFeedback[slide.stateVariable]);
				});

				this.fullAverage = sum / this.slides.length;
				this.fullAverage = Math.round(this.fullAverage * 100) / 100;
			},
		},
		computed: {
			...mapState(["startFeedback"]),
		},
		// on startFeedback change, update the slides
		watch: {
			startFeedback: {
				handler() {
					const aux = this.slides;
					this.slides = null;

					this.$nextTick(() => {
						this.slides = aux;
						this.calculateFullAverage();
					});
				},
				deep: true,
			},
		},
		mounted() {
			this.calculateFullAverage();
		},
	};
</script>

<style lang="scss" scoped>
	.next-button {
		:deep(.pi) {
			transform: rotate(180deg);
		}
	}

	.content {
		z-index: 5;
	}
</style>
