<template>
	<Dialog
		v-model:visible="$store.state.user_feedback.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<div class="d-flex align-items-center gap-3">
				<img src="@/assets/images/forms/user.png" width="50" />
				<div class="d-flex flex-column">
					<div class="h4 mb-0 text-white">Nombre Empleado</div>
					<div class="text-white">Feedback a Nombre Empleado</div>
				</div>
			</div>
		</template>

		<div class="d-flex flex-column gap-3 p-3">
			<div class="d-flex align-items-center justify-content-between mb-2">
				<div>
					<p class="text-primary mb-0"><b>Puntación total</b></p>
				</div>
				<Rating :starSize="20" :inactiveColor="'#c2cfc0'" />
			</div>
			<Accordion
				v-for="n in 3"
				:key="n"
				@toggleActive="toggleActive"
				ref="accordion"
				:index="n"
			>
				<template #header>
					<div class="d-flex align-items-center justify-content-between mb-2">
						<div>
							<p class="text-primary mb-0"><b>Comunicación</b></p>
						</div>
						<Rating :starSize="20" :inactiveColor="'#c2cfc0'" />
					</div>
				</template>
				<template #body>
					<div
						v-for="n in 4"
						:key="n"
						class="d-flex align-items-center justify-content-between mb-2"
					>
						<p class="text-primary mb-0">Comunicación efectiva</p>
						<Rating
							:starSize="20"
							:starColor="'#0b3e00'"
							:inactiveColor="'#49773e'"
						/>
					</div>
				</template>
			</Accordion>
		</div>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-center">
				<Button @click="close()" label="Cerrar" class="bg-yellow" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Dialog from "primevue/dialog";
	import Accordion from "@/components/user/partials/Accordion.vue";
	import Rating from "@/components/user/partials/Rating.vue";

	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			Dialog,
			Accordion,
			Rating,
		},
		props: {
			stateVariable: {
				type: String,
				required: true,
			},
		},
		methods: {
			...mapMutations(["toggleFormDialog"]),
			close() {
				this.toggleFormDialog({
					stateVariable: this.stateVariable,
					value: false,
				});
			},
			show() {},
		},
		computed: {
			...mapState(["dialogDefaults"]),
		},
	};
</script>
