<template>
	<div
		class="position-relative d-flex align-items-center justify-content-between px-5 bg-white w-100 h-100"
	>
		<div class="d-flex align-items-center gap-5">
			<span class="text-primary">About</span>
			<span class="text-primary">Contact</span>
		</div>
		<span class="text-primary">&copy;2023 Mrs. Wolf</span>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss">
	.position-relative {
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			width: 1px;
			height: 60%;
			transform: translateY(-50%);
			background-color: lightgray;
		}
	}
</style>
