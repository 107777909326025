<template>
	<Card class="custom-card">
		<template #content>
			<div class="container">
				<div class="d-flex justify-content-between align-items-center">
					<img
						@click="goToDashboard"
						src="@/assets/images/logotipo.png"
						class="logo cursor-pointer"
					/>
					<div
						v-if="userLogged != null || true"
						class="name d-flex align-items-center gap-3"
					>
						<span class="text-primary text-bold"
							>Hola&nbsp;<b>{{
								userLogged != null ? userLogged.name : "Nombre Empleado"
							}}</b></span
						>
						<img src="@/assets/images/misc/user.png" width="20" alt="" />
						<Avatar
							@click="toggleUserDashboardSidebar(!dashboard.showSidebar)"
							:label="totalNotifications"
							class="cursor-pointer text-white bg-salmon"
						/>
					</div>
				</div>
			</div>
		</template>
	</Card>
</template>

<script>
	import Card from "primevue/card";
	import Avatar from "primevue/avatar";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			Card,
			Avatar,
		},
		data() {
			return {
				notifications: [],
			};
		},
		computed: {
			...mapState(["userLogged", "notifications", "dashboard"]),
			totalNotifications() {
				return this.notifications.length.toString();
			},
		},
		methods: {
			...mapMutations([
				"toggleUserDashboardSidebar",
				"changeCurrentDashboardTab",
			]),
			goToDashboard() {
				this.changeCurrentDashboardTab(0);
				window.scrollTo(0, 0);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.custom-card {
		border-radius: 0 !important;

		.logo {
			height: 35px;
		}

		:deep(.p-card-body) {
			padding: 0 !important;
		}
	}

	// Mobiles
	@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.custom-card {
			.logo {
				height: 20px;
			}
		}
	}
</style>
