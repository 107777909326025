<template>
	<div>
		<div v-if="manager.selectedEmployee != null" class="d-flex gap-3 mb-3">
			<div
				@click="changeManagerViewingEmployeeDashboard(false)"
				class="h3 text-muted cursor-pointer"
			>
				Volver
			</div>
			<div class="h3 text-primary">Seguimiento {{ name }}</div>
		</div>
		<Card class="custom-card">
			<template #title>
				<div class="d-flex gap-3">
					<Avatar label="P" size="xlarge" />
					<div class="d-flex flex-column">
						<h3 class="mb-1 text-primary">
							{{ name }}
						</h3>
						<div class="d-flex flex-wrap gap-0 gap-md-4">
							<p class="mb-1 text-primary">
								{{ job }}
							</p>
							<p class="mb-1 text-primary">
								{{ email }}
							</p>
							<p v-if="phone != null" class="mb-1 text-primary">
								{{ phone }}
							</p>
						</div>
						<p v-if="functions != null" class="mb-0 text-muted">
							{{ functions }}
						</p>
					</div>
				</div>
			</template>
			<template #content>
				<hr class="mt-5 m-0" />

				<div
					class="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 py-md-5"
				>
					<template v-for="(tab, index) in activeTabs" :key="index">
						<template v-if="tab.show">
							<div
								:class="{ invisible: index == 0 }"
								class="d-none d-xl-block vertical-line"
							></div>
							<h3
								@click="changeCurrentDashboardTab(index)"
								:class="{ 'text-primary': dashboard.currentTab == index }"
								class="cursor-pointer text-center mb-3 mb-md-0"
							>
								{{ tab.name }}
							</h3>
							<div
								v-if="index == activeTabs.length - 1"
								class="invisible d-none d-xl-block vertical-line"
							></div>
						</template>
					</template>
				</div>
			</template>
		</Card>
	</div>
</template>

<script>
	import Card from "primevue/card";
	import Avatar from "primevue/avatar";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			Card,
			Avatar,
		},
		data() {
			return {
				tabs: [
					{
						name: "¿Cómo empezar?",
						active: true,
						show: true,
					},
					{
						name: "Invitados",
						active: false,
						show: true,
					},
					{
						name: "Feedback",
						active: false,
						show: true,
					},
					{
						name: "Plan de mejora",
						active: false,
						show: true,
					},
					{
						name: "Biblioteca",
						active: false,
						show: true,
					},
				],
			};
		},
		methods: {
			...mapMutations([
				"changeCurrentDashboardTab",
				"changeManagerViewingEmployeeDashboard",
			]),
		},
		computed: {
			...mapState(["dashboard", "manager", "userLogged"]),
			name() {
				if (this.userLogged.role.name != "invitado") {
					return this.manager.selectedEmployee != null
						? this.manager.selectedEmployee?.user.name
						: this.userLogged.name;
				} else {
					return this.userLogged.name;
				}
			},
			job() {
				if (this.userLogged.role.name != "invitado") {
					return this.manager.selectedEmployee != null
						? this.manager.selectedEmployee?.job
						: this.userLogged.employee.job;
				} else {
					return this.userLogged.guest.job;
				}
			},
			email() {
				if (this.userLogged.role.name != "invitado") {
					return this.manager.selectedEmployee != null
						? this.manager.selectedEmployee?.user.email
						: this.userLogged.email;
				} else {
					return this.userLogged.email;
				}
			},
			phone() {
				if (this.userLogged.role.name != "invitado") {
					return this.manager.selectedEmployee != null
						? this.manager.selectedEmployee?.phone
						: this.userLogged.employee.phone;
				} else {
					return null;
				}
			},
			functions() {
				if (this.userLogged.role.name != "invitado") {
					return this.manager.selectedEmployee != null
						? this.manager.selectedEmployee?.functions
						: this.userLogged.employee.functions;
				} else {
					return null;
				}
			},
			activeTabs() {
				return this.tabs.filter((tab) => tab.show);
			},
		},
		mounted() {
			if (this.userLogged.role.name == "manager") {
				this.tabs[0].show = false;
			}

			// if (this.userLogged.role.name == "empleado") {
			// 	this.tabs[0].show = false;
			// }

			if (this.userLogged.role.name == "invitado") {
				this.tabs[0].show = false;
				this.tabs[1].show = false;
				this.tabs[4].show = false;
			}
		},
	};
</script>

<style lang="scss" scoped>
	.custom-card {
		&.p-card {
			:deep(.p-card-body) {
				padding-bottom: 0;

				.p-card-title {
					font-size: 1rem;
					margin-bottom: 0;
				}

				.p-card-content {
					padding: 0;

					.vertical-line {
						width: 0.5px;
						height: 3rem;
						background-color: #bfbfbf;
					}
				}
			}
		}
	}

	// Tablets
	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
		.custom-card {
			h3 {
				font-size: 1rem;
			}
		}
	}
</style>
