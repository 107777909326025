<template>
	<Card :class="[cardBg]">
		<template #content>
			<div :class="[{ show: showOverlay }, statusClass]" class="overlay"></div>
			<i
				class="pi pi-ellipsis-h dots"
				:class="[showOverlay ? 'text-white' : 'text-green']"
			></i>
			<div v-if="showStatus" class="status d-flex align-items-center gap-2">
				<div
					class="d-flex align-items-center justify-content-center rounded-circle text-white status-icon"
					:class="[
						{ 'bg-yellow': statusText == 'Pendiente' },
						{ 'bg-green': statusText == 'Aprobado' },
						{ 'bg-salmon': statusText == 'Rechazado' },
					]"
				>
					<i
						class="pi text-white"
						:class="[
							{ 'pi-ellipsis-h': statusText == 'Pendiente' },
							{ 'pi-check': statusText == 'Aprobado' },
							{ 'pi-times': statusText == 'Rechazado' },
						]"
					></i>
				</div>
				<div :class="[showOverlay ? 'text-white' : 'text-muted']">
					{{ statusText }}
				</div>
			</div>
			<div class="d-flex align-items-center gap-2 gap-xl-3 mb-3">
				<Avatar label="P" size="xlarge" shape="circle" />
				<div class="d-flex flex-column">
					<h3 class="text-primary">{{ title }}</h3>
					<p class="mb-0 text-primary">{{ subtitle }}</p>
				</div>
			</div>

			<slot name="description" />

			<div v-if="showAction" class="w-100 d-flex justify-content-center">
				<slot name="action" />
			</div>
		</template>
	</Card>
</template>

<script>
	import Card from "primevue/card";
	import Avatar from "primevue/avatar";

	export default {
		components: {
			Card,
			Avatar,
		},
		props: {
			cardBg: {
				type: String,
				default: "bg-white",
			},
			showOverlay: {
				type: Boolean,
				default: false,
			},
			statusText: {
				type: String,
				default: "Pendiente",
			},
			statusClass: {
				type: String,
				default: "pending",
			},
			showStatus: {
				type: Boolean,
				default: true,
			},
			showAction: {
				type: Boolean,
				default: true,
			},
			actionText: {
				type: String,
				default: "Validar",
			},
			title: {
				type: String,
				default: "Nombre del invitado",
			},
			subtitle: {
				type: String,
				default: "Nombre del puesto de trabajo",
			},
		},
	};
</script>

<style lang="scss" scoped>
	.p-card {
		overflow: hidden;

		:deep(.p-card-body) {
			padding: 3rem 1rem;
			position: relative;

			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease-in-out;
				z-index: 1;

				&.pending {
					background-color: rgba(0, 0, 0, 0.5);
				}

				&.rejected {
					background-color: rgba(183, 35, 35, 0.5);
				}

				&.show {
					opacity: 1;
					visibility: visible;
				}
			}

			.status {
				position: absolute;
				top: 1rem;
				left: 1rem;
				z-index: 5;
			}

			.dots {
				position: absolute;
				top: 1rem;
				right: 1rem;
				font-size: 1.5rem;
				font-weight: 700;
				cursor: pointer;
				z-index: 5;
				transition: $transition;

				&:hover {
					color: darken($green, 20%);
				}
			}

			.status-icon {
				width: 25px;
				height: 25px;
			}

			.p-card-content {
				padding: 0;
			}
		}
	}

	// Large tablets
	@media only screen and (min-width: $large-tablet-min-width) and (max-width: $large-tablet-max-width) {
		.p-card {
			h3 {
				font-size: 1.2rem;
			}
		}
	}
</style>
