<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-primary title">
				Crear nueva cuenta de empresa
			</div>
			<div class="col-12">
				<p class="text-muted">Introduce tus datos para crear tu cuenta</p>
			</div>
			<div class="col-12">
				<InputText
					label="Nombre de empresa"
					:error="form.errors.get('name')"
					@change-value="(value) => (form.name = value)"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputText
					label="Número de empleados"
					:error="form.errors.get('employees_number')"
					@change-value="(value) => (form.employees_number = value)"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputText
					label="Correo electrónico"
					:error="form.errors.get('email')"
					@change-value="(value) => (form.email = value)"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Contraseña"
					:error="form.errors.get('password')"
					@change-value="(value) => (form.password = value)"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Repetir contraseña"
					:error="form.errors.get('password_confirm')"
					:feedback="false"
					@change-value="(value) => (form.password_confirm = value)"
				/>
			</div>
			<div class="col-12 mt-4">
				<CheckBox
					label="Acepto la política de privacidad"
					:error="form.errors.get('terms_and_conditions')"
					@change-value="(value) => (form.terms_and_conditions = value)"
					:binary="true"
				/>
			</div>
			<div class="col-12 d-flex justify-content-between mt-4">
				<Button
					:disabled="form.busy"
					@click="$parent.showForm = 'login'"
					label="Volver"
					class="bg-yellow"
				/>
				<Button
					@click="save()"
					label="Crear"
					class="bg-salmon"
					:disabled="form.busy"
				/>
			</div>
		</div>
	</form>
</template>

<script>
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		data: () => ({
			form: new Form({
				terms_and_conditions: false,
			}),
		}),
		methods: {
			...mapActions(["sendForm"]),
			save() {
				const url = `/register`;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.$parent.showRegisterAlert();

						this.$nextTick(() => {
							this.$parent.showForm = "login";
						});
					} else {
						this.$parent.showError();
					}
				});
			},
		},
	};
</script>
