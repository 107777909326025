<template>
	<div>
		<NewEmployeeDialog :route="'employees'" :stateVariable="'user_employees'" />

		<div class="d-flex align-items-center gap-4">
			<h4
				@click="currentTab = 'empleados'"
				class="cursor-pointer"
				:class="[currentTab == 'empleados' ? 'text-primary' : 'text-muted']"
			>
				Empleados
			</h4>
			<h4
				@click="currentTab = 'feedback-pendiente'"
				class="cursor-pointer"
				:class="[
					currentTab == 'feedback-pendiente' ? 'text-primary' : 'text-muted',
				]"
			>
				Feedback pendiente
			</h4>
		</div>

		<TotalAndSearch
			class="mt-3"
			:title="'Nuevo empleado'"
			:actionButtonLabel="'Añadir empleado'"
			:total="user_employees.listTotal"
			@actionButtonClick="addEmployee"
			@search="search"
		/>

		<div
			v-if="currentTab == 'empleados' && user_employees.list?.length"
			class="row mt-0 gy-4"
		>
			<div
				v-for="(employee, index) in user_employees.list"
				:key="index"
				class="col-12 col-md-6 col-lg-4"
			>
				<UserCard
					:showOverlay="false"
					:showStatus="false"
					:title="employee.user.name"
					:subtitle="employee.job"
				>
					<template #description>
						<div class="d-flex justify-content-between align-items-center">
							<span class="text-primary"><b>Email</b></span>
							<span class="text-primary">{{ employee.user.email }}</span>
						</div>
						<div class="d-flex justify-content-between align-items-center mb-3">
							<span class="text-primary"><b>Teléfono</b></span>
							<span class="text-primary">{{ employee.phone }}</span>
						</div>
					</template>
					<template #action>
						<Button
							@click="selectEmployee(employee)"
							label="Seguimiento"
							class="bg-lightyellow text-yellow"
						/>
					</template>
				</UserCard>
			</div>

			<div
				v-if="showLoadMore"
				class="col-12 d-flex justify-content-center mt-5"
			>
				<Button
					@click="loadMore"
					label="Ver más"
					class="bg-primary text-white"
				/>
			</div>
		</div>

		<div v-else-if="currentTab == 'feedback-pendiente'" class="row mt-3">
			<div class="col-12 col-md-6 col-lg-4">
				<UserCard :showOverlay="false" :showStatus="false">
					<template #action>
						<Button label="Dar feedback" class="bg-green text-white" />
					</template>
				</UserCard>
			</div>
		</div>
	</div>
</template>

<script>
	import UserCard from "@/components/user/partials/UserCard.vue";
	import TotalAndSearch from "@/components/user/partials/TotalAndSearch.vue";
	import NewEmployeeDialog from "@/components/user/partials/dialogs/NewEmployeeDialog.vue";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			UserCard,
			TotalAndSearch,
			NewEmployeeDialog,
		},
		data() {
			return {
				currentTab: "empleados",
				rows: 3,
				page: 0,
				lastSearch: null,
			};
		},
		methods: {
			...mapActions(["getRegisters"]),
			...mapMutations([
				"toggleFormDialog",
				"toggleLoading",
				"changeManagerSelectedEmployee",
				"changeManagerViewingEmployeeDashboard",
			]),
			addEmployee() {
				this.toggleFormDialog({
					stateVariable: "user_employees",
					show: true,
				});
			},
			loadMore() {
				this.toggleLoading();

				this.page++;

				this.getRegisters({
					stateVariable: "user_employees",
					route: "employees",
					page: this.page,
					rows: this.rows,
					concatList: true,
					search: this.lastSearch,
				}).then(() => {
					this.toggleLoading();
				});
			},
			search(params) {
				this.lastSearch = params;
				this.toggleLoading();

				this.page = 0;

				this.getRegisters({
					stateVariable: "user_employees",
					route: "employees",
					page: this.page,
					rows: this.rows,
					search: params,
				}).then(() => {
					this.toggleLoading();
				});
			},
			selectEmployee(employee) {
				this.changeManagerSelectedEmployee(employee);
				this.changeManagerViewingEmployeeDashboard(true);
			},
		},
		computed: {
			...mapState(["user_employees"]),
			showLoadMore() {
				return this.user_employees.listTotal > this.rows * (this.page + 1);
			},
		},
		mounted() {
			this.getRegisters({
				stateVariable: "user_employees",
				route: "employees",
				page: this.page,
				rows: this.rows,
			});
		},
	};
</script>
