const mutations = {
	// General
	changeUserLogged(state, n) {
		state.userLogged = n;
	},
	toggleSidebar(state, n) {
		state.showSidebar = n;
	},
	toggleLoading(state, n) {
		if (n === undefined) {
			state.loading = !state.loading;
		} else {
			state.loading = n;
		}
	},

	// Toasts
	changeSuccessToast(state, n) {
		state.successToast = n;
	},
	changeErrorToast(state, n) {
		state.errorToast = n;
	},
	changeWarningToast(state, n) {
		state.warningToast = n;
	},

	// Defaults
	changeCurrentTablePage(state, n) {
		state[n.stateVariable].currentPage = n.event.page;
		state[n.stateVariable].rows = n.event.rows;
	},
	toggleFormDialog(state, n) {
		state[n.stateVariable].dialog = n.show;
	},
	changeFormDialogMode(state, n) {
		state[n.stateVariable].dialogMode = n.dialogMode;
	},
	changeCurrentRegister(state, n) {
		state[n.stateVariable].register = n.register;
	},

	// Users
	// Dashboard
	changeCurrentDashboardTab(state, n) {
		state.dashboard.currentTab = n;
	},
	toggleUserDashboardSidebar(state, n) {
		state.dashboard.showSidebar = n;
	},

	// Manager
	changeManagerSelectedEmployee(state, n) {
		state.manager.selectedEmployee = n;
	},
	changeManagerViewingEmployeeDashboard(state, n) {
		state.manager.viewingEmployeeDashboard = n;
	},

	// Start feedback
	toggleStartFeedback(state, n) {
		state.startFeedback.showStartFeedback = n;
	},
	changeCurrentStartFeedbackSlide(state, n) {
		if (n < 0) n = 0;
		if (n > state.startFeedback.totalSlides - 1)
			n = state.startFeedback.totalSlides - 1;
		state.startFeedback.currentSlide = n;
	},
	changeCommunicationRatings(state, n) {
		state.startFeedback.communicationRatings = n;
	},
	changeBehaviorRatings(state, n) {
		state.startFeedback.behaviorRatings = n;
	},
	changePerformanceRatings(state, n) {
		state.startFeedback.performanceRatings = n;
	},

	// Guest
	changeGuestSelectedEmployee(state, n) {
		state.guest.selectedEmployee = n;
	},
};

export default mutations;
