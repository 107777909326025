<template>
	<div>
		<NewGuests @ready="newGuestsReady = true" />
		<DefinedGuests @ready="definedGuestsReady = true" class="mt-5" />
	</div>
</template>

<script>
	import NewGuests from "@/components/user/guests/NewGuests.vue";
	import DefinedGuests from "@/components/user/guests/DefinedGuests.vue";
	import { mapMutations } from "vuex";

	export default {
		components: {
			NewGuests,
			DefinedGuests,
		},
		data() {
			return {
				newGuestsReady: false,
				definedGuestsReady: false,
			};
		},
		methods: {
			...mapMutations(["toggleLoading"]),
		},
		mounted() {
			this.toggleLoading(true);
		},
		watch: {
			newGuestsReady() {
				if (this.newGuestsReady && this.definedGuestsReady) {
					this.toggleLoading(false);
				}
			},
			definedGuestsReady() {
				if (this.newGuestsReady && this.definedGuestsReady) {
					this.toggleLoading(false);
				}
			},
		},
	};
</script>
