<template>
	<Dialog
		v-model:visible="$store.state.user_employees.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<div class="d-flex align-items-center gap-3">
				<img src="@/assets/images/forms/user.png" width="50" />
				<FormTitle :title="title" :class="'text-white'" />
			</div>
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 mt-2">
				<div class="col-12">
					<h3 class="initial-font mb-0 text-muted">Datos empleado</h3>
				</div>
				<div class="col-12">
					<InputText
						ref="name"
						label="Nombre y apellidos"
						:disabled="disabled"
						:error="form.errors.get('name')"
						@change-value="(value) => (form.name = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="job"
						label="Puesto de trabajo"
						:disabled="disabled"
						:error="form.errors.get('job')"
						@change-value="(value) => (form.job = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="functions"
						label="Funciones"
						:disabled="disabled"
						:error="form.errors.get('functions')"
						@change-value="(value) => (form.functions = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="email"
						label="Email"
						:disabled="disabled"
						:error="form.errors.get('email')"
						@change-value="(value) => (form.email = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="phone"
						label="Teléfono"
						:disabled="disabled"
						:error="form.errors.get('phone')"
						@change-value="(value) => (form.phone = value)"
					/>
				</div>
				<div class="col-12">
					<hr class="mb-0" />
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-center">
				<Button
					@click="save()"
					label="Enviar"
					class="bg-yellow"
					:disabled="form.busy"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "usuario",
			title: `Añadir usuario`,
			disabled: false,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.user_employees.register != null;
				const url = `/employees${
					update ? `/${this.user_employees.register.id}` : ""
				}`;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: 0,
							rows: 3,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						if (this.$refs[key] != null) {
							this.$refs[key].model = null;
						}
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.user_employees.register;

				if (register != null) {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}

					if (this.user_employees.dialogMode == "edit") {
						this.title = `Editar ${this.modelName}`;
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "user_employees", "user_employeeLogged"]),
			isCustomer() {
				return this.user_employeeLogged.role.name == "admin";
			},
		},
	};
</script>
