<template>
	<Dialog
		v-model:visible="$store.state.user_wants_to_improve.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<div class="d-flex align-items-center gap-3">
				<Avatar label="P" size="large" shape="circle" />
				<div class="d-flex flex-column">
					<h3 class="text-white">{{ guest.selectedEmployee.user.name }}</h3>
					<p class="mb-0 text-white">{{ guest.selectedEmployee.job }}</p>
				</div>
			</div>
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 mt-2">
				<div class="col-12">
					<h3 class="initial-font mb-0 text-muted">Quiere mejorar</h3>
				</div>
				<div class="col-12">
					<TextArea
						ref="what_do_you_think"
						label="¿Qué crees que tiene que hacer para mejorar?"
						:placeholder="'Escribe tu respuesta.'"
						:disabled="disabled"
						:error="form.errors.get('what_do_you_think')"
						@change-value="(value) => (form.what_do_you_think = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="what_you_can_do"
						label="¿Qué puedes hacer tú para ayudarle?"
						:placeholder="'Escribe tu respuesta.'"
						:disabled="disabled"
						:error="form.errors.get('what_you_can_do')"
						@change-value="(value) => (form.what_you_can_do = value)"
					/>
				</div>
				<div class="col-12">
					<h3 class="initial-font mb-0 text-muted">¿Qué ha ocurrido?</h3>
				</div>
				<div class="col-12 d-flex flex-column gap-3">
					<div
						v-for="(option, index) in options"
						:key="index"
						@click="toggleOptionSelected(index)"
						:class="[
							option.selected
								? 'bg-primary text-white'
								: 'bg-lightgreen text-primary',
						]"
						class="cursor-pointer rounded px-3 py-2"
					>
						<b>{{ option.text }}</b>
					</div>
				</div>
				<div
					v-if="form.errors.get('option_selected') != null"
					class="text-danger"
				>
					{{ form.errors.get("option_selected") }}
				</div>
				<div class="col-12">
					<hr class="mb-0" />
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-center">
				<Button
					@click="save()"
					label="Enviar"
					class="bg-yellow"
					:disabled="form.busy"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Dialog from "primevue/dialog";
	import Avatar from "primevue/avatar";

	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Dialog,
			Avatar,
		},
		props: {
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			disabled: false,
			options: [
				{ selected: false, text: "1. Ha empeorado mucho" },
				{ selected: false, text: "2. Ha empeorado" },
				{ selected: false, text: "3. Está igual" },
				{ selected: false, text: "4. Ha mejorado" },
				{ selected: false, text: "5. Ha mejorado mucho" },
			],
		}),
		methods: {
			...mapActions(["getUsers", "sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const url = `/wants-to-improve`;

				this.form.option_selected = this.options.find(
					(option) => option.selected
				)?.text;

				this.form.selectedEmployee = this.guest.selectedEmployee;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});
					}
				});
			},
			hide() {
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.user_wants_to_improve.register?.user;

				if (register != null) {
					for (const key in register) {
						if (Object.hasOwnProperty.call(register, key)) {
							if (this.$refs[key] != undefined) {
								this.$refs[key].model = register[key];
							}
						}
					}

					if (this.user_wants_to_improve.dialogMode == "edit") {
						this.disabled = false;
					} else {
						this.disabled = true;
					}
				}
			},
			toggleOptionSelected(index) {
				this.options.forEach((option, i) => {
					if (i == index) {
						option.selected = true;
					} else {
						option.selected = false;
					}
				});
			},
		},
		computed: {
			...mapState(["dialogDefaults", "user_wants_to_improve", "guest"]),
		},
	};
</script>
