<template>
	<div class="d-flex">
		<div
			v-if="fileName != null"
			@click="
				downloadFile({
					downloadRoute: downloadRoute,
					folder: folder,
					fileName: fileName,
					downloadFileName: downloadFileName,
				})
			"
			class="bg-primary text-white px-3 py-2 rounded cursor-pointer download-link"
		>
			Descargar
		</div>
		<div v-else class="text-muted">Sin archivo</div>
	</div>
</template>

<script>
	import { mapActions } from "vuex";

	export default {
		props: {
			downloadRoute: {
				type: String,
				required: true,
			},
			folder: {
				type: String,
				required: true,
			},
			fileName: {
				type: String,
				required: true,
			},
			downloadFileName: {
				type: String,
				required: false,
				default: null,
			},
		},
		methods: {
			...mapActions(["downloadFile"]),
		},
	};
</script>
