<template>
	<div id="user-layout" class="pb-5">
		<Toast />
		<ConfirmDialog />
		<Loading />

		<Sidebar />

		<div class="top-bar-section">
			<TopBar />
		</div>

		<div class="mt-5">
			<div class="container">
				<router-view v-slot="{ Component }">
					<transition name="slide" mode="out-in">
						<component :is="Component" :key="$route.path" />
					</transition>
				</router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import TopBar from "@/components/user/panels/TopBar.vue";
	import Sidebar from "@/components/user/panels/Sidebar.vue";
	import Loading from "@/components/admin/partials/Loading.vue";

	import Toast from "primevue/toast";
	import ConfirmDialog from "primevue/confirmdialog";

	import { mapState, mapMutations, mapActions } from "vuex";

	export default {
		components: {
			Loading,
			TopBar,
			Sidebar,
			Toast,
			ConfirmDialog,
		},
		data() {
			return {};
		},
		computed: {
			...mapState(["errorToast", "successToast", "warningToast"]),
		},
		methods: {
			...mapActions(["setFormAccessToken"]),
			...mapMutations([
				"changeSuccessToast",
				"changeErrorToast",
				"changeWarningToast",
			]),
		},
		mounted() {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);

			this.setFormAccessToken();
		},
		watch: {
			successToast() {
				if (this.successToast != null) {
					this.$toast.add({
						severity: "success",
						summary: "Acción realizada",
						detail:
							this.successToast == true
								? "Operación realizada con éxito"
								: this.successToast,
						life: 3000,
					});
				}

				this.changeSuccessToast(null);
			},
			errorToast() {
				if (this.errorToast != null) {
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail:
							this.errorToast == true
								? "Ha ocurrido un error"
								: this.errorToast,
						life: 3000,
					});
				}

				this.changeErrorToast(null);
			},
			warningToast() {
				if (this.warningToast != null) {
					this.$toast.add({
						severity: "warn",
						summary: "Advertencia",
						detail:
							this.warningToast == true
								? "Ha ocurrido un error"
								: this.warningToast,
						life: 3000,
					});
				}

				this.changeWarningToast(null);
			},
		},
	};
</script>

<style lang="scss" scoped>
	#user-layout {
		background-color: rgb(245, 245, 245);
		position: relative;
		min-height: 100vh;

		.top-bar-section {
			position: sticky;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}

		.slide-enter-active,
		.slide-leave-active {
			transition: all 0.2s ease;
		}

		.slide-enter-from,
		.slide-leave-to {
			transform: translateX(30%);
			opacity: 0;
		}
	}
</style>
