<template>
	<div>
		<TotalAndSearch
			class="mt-3"
			:title="'Feedback pendientes'"
			:total="guest_pending_feedbacks.listTotal"
			:showActionButton="false"
			@search="search"
		/>

		<div v-if="guest_pending_feedbacks.list?.length" class="row mt-0 gy-4">
			<div
				v-for="(employee, index) in guest_pending_feedbacks.list"
				:key="index"
				class="col-12 col-md-6 col-lg-4"
			>
				<UserCard
					:showOverlay="false"
					:showStatus="false"
					:title="employee.user.name"
					:subtitle="employee.job"
				>
					<template #action>
						<Button
							@click="startFeedback(employee)"
							label="Dar feedback"
							class="bg-green text-white"
						/>
					</template>
				</UserCard>
			</div>

			<div
				v-if="showLoadMore"
				class="col-12 d-flex justify-content-center mt-5"
			>
				<Button
					@click="loadMore"
					label="Ver más"
					class="bg-primary text-white"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import UserCard from "@/components/user/partials/UserCard.vue";
	import TotalAndSearch from "@/components/user/partials/TotalAndSearch.vue";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			UserCard,
			TotalAndSearch,
		},
		data() {
			return {
				currentTab: "empleados",
				rows: 3,
				page: 0,
				lastSearch: null,
			};
		},
		methods: {
			...mapActions(["getRegisters"]),
			...mapMutations([
				"toggleFormDialog",
				"toggleLoading",
				"changeManagerSelectedEmployee",
				"changeManagerViewingEmployeeDashboard",
				"toggleStartFeedback",
				"changeGuestSelectedEmployee",
			]),
			startFeedback(employee) {
				this.changeGuestSelectedEmployee(employee);
				this.toggleStartFeedback(true);
			},
			loadMore() {
				this.toggleLoading();

				this.page++;

				this.getRegisters({
					stateVariable: "guest_pending_feedbacks",
					route: "employees",
					page: this.page,
					rows: this.rows,
					concatList: true,
					search: this.lastSearch,
					additionalParams: {
						fromGuest: true,
						status: "pending",
					},
				}).then(() => {
					this.toggleLoading();
				});
			},
			search(params) {
				this.lastSearch = params;
				this.toggleLoading();

				this.page = 0;

				this.getRegisters({
					stateVariable: "guest_pending_feedbacks",
					route: "employees",
					page: this.page,
					rows: this.rows,
					search: params,
					additionalParams: {
						fromGuest: true,
						status: "pending",
					},
				}).then(() => {
					this.toggleLoading();
				});
			},
		},
		computed: {
			...mapState(["guest_pending_feedbacks"]),
			showLoadMore() {
				return (
					this.guest_pending_feedbacks.listTotal > this.rows * (this.page + 1)
				);
			},
		},
		mounted() {
			this.getRegisters({
				stateVariable: "guest_pending_feedbacks",
				route: "employees",
				page: this.page,
				rows: this.rows,
				additionalParams: {
					fromGuest: true,
					status: "pending",
				},
			});
		},
	};
</script>
