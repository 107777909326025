<template>
	<div class="d-flex">
		<div class="left-section"></div>
		<div
			class="right-section d-flex align-items-center justify-content-between flex-column py-5"
		>
			<!-- <div
				v-if="startFeedback.currentSlide != 4"
				class="arrows d-flex justify-content-between gap-2"
			>
				<div
					v-if="startFeedback.currentSlide == 0"
					@click="toggleStartFeedback(false)"
					class="action d-flex justify-content-center align-items-center bg-gray text-white"
				>
					<i class="pi pi-times"></i>
				</div>
				<div
					@click="prevSlide()"
					class="action d-flex justify-content-center align-items-center bg-primary text-white"
					:class="[activeIndex == 0 ? 'invisible' : '']"
				>
					<i class="pi pi-chevron-left"></i>
				</div>
				<div
					@click="nextSlide()"
					class="action d-flex justify-content-center align-items-center bg-primary text-white"
					:class="[
						activeIndex == totalSlides[startFeedback.currentSlide - 1] - 1
							? 'invisible'
							: '',
					]"
				>
					<i class="pi pi-chevron-right"></i>
				</div>
			</div> -->

			<div
				class="content w-75 d-none d-md-flex justify-content-between align-items-center"
			>
				<div
					:class="[
						startFeedback.currentSlide >= 1 ? 'text-yellow' : 'text-primary',
					]"
					class="section cursor-pointer h5 mb-0"
					@click="goSection(1)"
				>
					<b>Comunicación</b>
				</div>
				<div
					:class="[
						startFeedback.currentSlide >= 2 ? 'text-yellow' : 'text-primary',
					]"
					class="section cursor-pointer h5 mb-0"
					@click="goSection(2)"
				>
					<b>Comportamiento</b>
				</div>
				<div
					:class="[
						startFeedback.currentSlide >= 3 ? 'text-yellow' : 'text-primary',
					]"
					class="section cursor-pointer h5 mb-0"
					@click="goSection(3)"
				>
					<b>Rendimiento</b>
				</div>
			</div>
			<CommunicationSlide
				:class="[startFeedback.currentSlide == 1 ? 'd-block' : 'd-none']"
				ref="CommunicationSlide"
			/>
			<BehaviorSlide
				:class="[startFeedback.currentSlide == 2 ? 'd-block' : 'd-none']"
				ref="BehaviorSlide"
			/>
			<PerformanceSlide
				:class="[startFeedback.currentSlide == 3 ? 'd-block' : 'd-none']"
				ref="PerformanceSlide"
			/>
			<LastSlide
				:class="[startFeedback.currentSlide == 4 ? 'd-block' : 'd-none']"
			/>
			<div
				class="content w-75 d-flex flex-column align-items-center justify-content-end"
			>
				<Button
					@click="nextSlide()"
					class="d-none d-md-flex next-button bg-yellow mb-5"
					:label="startFeedback.currentSlide != 4 ? 'Siguiente' : 'Enviar'"
					icon="pi pi-reply"
					iconPos="right"
				/>
				<div
					class="swiper-scrollbar mt-3 mt-md-0"
					:style="[startFeedback.currentSlide == 4 ? 'opacity: 0' : '']"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
	import CommunicationSlide from "@/components/user/partials/feedback_form/CommunicationSlide.vue";
	import BehaviorSlide from "@/components/user/partials/feedback_form/BehaviorSlide.vue";
	import PerformanceSlide from "@/components/user/partials/feedback_form/PerformanceSlide.vue";
	import LastSlide from "@/components/user/partials/feedback_form/LastSlide.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			CommunicationSlide,
			BehaviorSlide,
			PerformanceSlide,
			LastSlide,
		},
		data() {
			return {
				activeIndex: 0,
				totalSlides: [6, 10, 5],
			};
		},
		methods: {
			...mapActions(["sendFeedback"]),
			...mapMutations([
				"changeCurrentStartFeedbackSlide",
				"toggleLoading",
				"toggleStartFeedback",
			]),
			goNextSlider() {
				if (this.startFeedback.currentSlide != 4) {
					this.changeCurrentStartFeedbackSlide(
						this.startFeedback.currentSlide + 1
					);

					this.activeIndex = 0;
				}
			},
			// prevSlide() {
			// 	if (this.startFeedback.currentSlide == 1) {
			// 		this.$refs.CommunicationSlide.goPreviousSlide();
			// 	}

			// 	if (this.startFeedback.currentSlide == 2) {
			// 		this.$refs.BehaviorSlide.goPreviousSlide();
			// 	}

			// 	if (this.startFeedback.currentSlide == 3) {
			// 		this.$refs.PerformanceSlide.goPreviousSlide();
			// 	}
			// },
			nextSlide() {
				if (this.startFeedback.currentSlide == 1) {
					if (
						this.$refs.CommunicationSlide.getCurrentSlide() ==
						this.totalSlides[0] - 1
					) {
						this.goNextSlider();
						return;
					}

					this.$refs.CommunicationSlide.goNextSlide();
				}

				if (this.startFeedback.currentSlide == 2) {
					if (
						this.$refs.BehaviorSlide.getCurrentSlide() ==
						this.totalSlides[1] - 1
					) {
						this.goNextSlider();
						return;
					}

					this.$refs.BehaviorSlide.goNextSlide();
				}

				if (this.startFeedback.currentSlide == 3) {
					if (
						this.$refs.PerformanceSlide.getCurrentSlide() ==
						this.totalSlides[2] - 1
					) {
						this.goNextSlider();
						return;
					}

					this.$refs.PerformanceSlide.goNextSlide();
				}

				if (this.startFeedback.currentSlide == 4) {
					this.toggleLoading();

					this.sendFeedback().then(() => {
						this.changeCurrentStartFeedbackSlide(0);

						this.activeIndex = 0;

						this.toggleStartFeedback(false);

						setTimeout(() => {
							this.toggleLoading(false);
						}, 200);
					});
				}
			},
			goSection(section) {
				this.changeCurrentStartFeedbackSlide(section);

				this.activeIndex = 0;

				this.$nextTick(() => {
					if (section == 1) {
						this.$refs.CommunicationSlide.goFirstSlide();
					}

					if (section == 2) {
						this.$refs.BehaviorSlide.goFirstSlide();
					}

					if (section == 3) {
						this.$refs.PerformanceSlide.goFirstSlide();
					}
				});
			},
		},
		mounted() {
			document.addEventListener("keydown", (e) => {
				if (
					e.key === "Enter" &&
					this.startFeedback.currentSlide != 0 &&
					!this.loading
				) {
					this.nextSlide();
				}
			});
		},
		computed: {
			...mapState(["startFeedback", "loading"]),
			showNextButton() {
				return (
					this.activeIndex ==
						this.totalSlides[this.startFeedback.currentSlide - 1] - 1 ||
					this.startFeedback.currentSlide == 4
				);
			},
		},
		watch: {
			"startFeedback.currentSlide": function () {
				this.activeIndex = 0;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.left-section {
		background-image: url("@/assets/images/misc/background-1.jpg");
		background-position: center;
		background-size: cover;
		height: 100%;
		width: 30%;
	}

	.right-section {
		height: 100%;
		width: 70%;
		overflow: hidden;
		position: relative;

		.arrows {
			position: absolute;
			top: 50%;
			left: 1rem;
			right: 1rem;
			z-index: 5;
			transform: translateY(-50%);

			.action {
				height: 1.5rem;
				width: 1.5rem;
				transition: $transition;

				i {
					font-size: 0.7rem;
				}

				&:hover {
					cursor: pointer;
					background-color: darken($primary, 10%) !important;
				}
			}
		}

		.content {
			position: relative;
			z-index: 5;
		}

		.swiper-scrollbar {
			position: relative;
			left: unset;
			top: unset;
			width: 100%;
			background-color: $primary;
			z-index: 5;

			:deep(.swiper-pagination-progressbar-fill) {
				background-color: $yellow;
			}
		}
	}

	.next-button {
		:deep(.pi) {
			transform: rotate(180deg);
		}
	}

	// Mobiles
	@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.left-section {
			display: none;
		}

		.right-section {
			width: 100%;
			background-image: url("@/assets/images/misc/background-1.jpg");
			background-position: center;
			background-size: cover;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
			}

			.content {
				h1 {
					font-size: 3rem;
				}
			}
		}
	}

	// Tablets
	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
		.left-section {
			display: none;
		}

		.right-section {
			width: 100%;
			background-image: url("@/assets/images/misc/background-1.jpg");
			background-position: center;
			background-size: cover;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
			}

			.content {
				h1 {
					font-size: 3rem;
				}
			}
		}
	}
</style>
