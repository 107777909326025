<template>
	<TableDefault
		:route="route"
		:stateVariable="stateVariable"
		:list="customers.list"
		:total="customers.listTotal"
		:filters="filters"
		:delete="'customers'"
	>
		<template #columns>
			<Column header="Nombre de la empresa" field="name"></Column>
			<Column header="Nº de empleados">
				<template #body="slotProps">
					<span v-if="slotProps.data.employees_number != null"
						>{{ slotProps.data.employees_number }}
					</span>
					<span v-else class="text-muted">Sin información</span>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "name",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "employees_number",
						value: null,
						type: "string",
						placeholder: "Nº de empleados",
					},
				],
			};
		},
		methods: {
			...mapActions(["incrementViews"]),
		},
		computed: {
			...mapState(["customers"]),
		},
	};
</script>
