<template>
	<div class="start-feedback bg-green">
		<div class="arrows d-flex gap-2">
			<div
				v-if="startFeedback.currentSlide == 0"
				@click="toggleStartFeedback(false)"
				class="action d-flex justify-content-center align-items-center bg-gray text-white"
			>
				<i class="pi pi-times"></i>
			</div>
			<div
				v-else
				@click="changeCurrentStartFeedbackSlide(startFeedback.currentSlide - 1)"
				class="action d-flex justify-content-center align-items-center bg-green text-white"
			>
				<i class="pi pi-chevron-left"></i>
			</div>
			<div
				@click="changeCurrentStartFeedbackSlide(startFeedback.currentSlide + 1)"
				class="action d-flex justify-content-center align-items-center bg-green text-white"
			>
				<i class="pi pi-chevron-right"></i>
			</div>
		</div>

		<FirstSlide
			:class="[startFeedback.currentSlide == 0 ? 'd-block' : 'd-none']"
			class="slide"
		/>
		<Slider
			:class="[startFeedback.currentSlide != 0 ? 'd-block' : 'd-none']"
			class="slide"
		/>
	</div>
</template>

<script>
	import FirstSlide from "@/components/user/partials/feedback_form/FirstSlide.vue";
	import Slider from "@/components/user/partials/feedback_form/Slider.vue";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			FirstSlide,
			Slider,
		},
		methods: {
			...mapMutations([
				"changeCurrentStartFeedbackSlide",
				"toggleStartFeedback",
			]),
		},
		computed: {
			...mapState(["startFeedback"]),
		},
	};
</script>

<style lang="scss" scoped>
	.start-feedback {
		aspect-ratio: 16/9;
		position: relative;

		.arrows {
			position: absolute;
			top: 1rem;
			left: 1rem;
			z-index: 5;

			.action {
				height: 1.5rem;
				width: 1.5rem;
				transition: $transition;

				i {
					font-size: 0.7rem;
				}

				&:hover {
					cursor: pointer;
					background-color: darken($green, 10%) !important;
				}
			}
		}

		.slide {
			width: 100%;
			height: 100%;
		}
	}

	// Mobiles
	@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.start-feedback {
			aspect-ratio: 15/20;

			.arrows {
				.action {
					&:hover {
						background-color: $green !important;
					}
				}
			}
		}
	}

	// Tablets
	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
		.start-feedback {
			aspect-ratio: 1;

			.arrows {
				.action {
					&:hover {
						background-color: $green !important;
					}
				}
			}
		}
	}

	// Large tablets
	@media only screen and (min-width: $large-tablet-min-width) and (max-width: $large-tablet-max-width) {
		.start-feedback {
			aspect-ratio: 10/7;
			.arrows {
				.action {
					&:hover {
						background-color: $green !important;
					}
				}
			}
		}
	}
</style>
