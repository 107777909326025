<template>
	<Sidebar
		class="custom-sidebar"
		v-model:visible="dashboard.showSidebar"
		:baseZIndex="10000"
		position="right"
	>
		<template #header>
			<div class="w-100 d-flex align-items-center gap-3 p-4">
				<h5 class="text-primary mb-0"><b>Notificaciones</b></h5>
				<p class="notification-count mb-0 text-primary">3 nuevas</p>
			</div>
		</template>
		<div class="d-flex flex-column justify-content-between h-100">
			<div class="notifications d-flex flex-column gap-2 p-4 pt-0">
				<div
					v-for="n in 18"
					:key="n"
					class="notification d-flex align-items-center gap-3 p-3 rounded cursor-pointer"
				>
					<Avatar label="P" size="large" shape="circle" />
					<div class="d-flex flex-column">
						<p class="text-primary mb-1">
							<b>Solicita revisión de invitados</b>
						</p>
						<p class="mb-0 text-primary">por Nombre Empleado</p>
					</div>
				</div>
			</div>
			<div class="button-container p-4">
				<Button @click="logout" label="Cerrar sesión" class="w-100" />
			</div>
		</div>
	</Sidebar>
</template>

<script>
	import Sidebar from "primevue/sidebar";
	import Avatar from "primevue/avatar";

	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Sidebar,
			Avatar,
		},
		computed: {
			...mapState(["dashboard"]),
		},
		methods: {
			...mapActions(["logout"]),
		},
	};
</script>

<style lang="scss">
	.custom-sidebar {
		width: 25rem !important;
		max-width: 95vw !important;

		.p-sidebar-header {
			justify-content: space-between;
			padding: 0 !important;
			padding-right: 1.25rem !important;
			box-shadow: $smooth-box-shadow;

			.p-sidebar-close {
				color: $primary !important;
			}

			.notification-count {
				transform: translateY(1.5px);
			}
		}

		.p-sidebar-content {
			padding: 0 !important;

			.notifications {
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 0;
				}

				.notification {
					transition: $transition;

					&:hover {
						background-color: $lightgreen;
					}
				}
			}

			.button-container {
				box-shadow: $smooth-box-shadow;
			}
		}
	}
</style>
