<template>
	<div>
		<!-- Dialogs -->
		<ImprovementPlanDialog stateVariable="user_improvement_plans" />

		<!-- Sugerencias -->
		<TotalAndSearch
			:title="'Sugerencias de mejora'"
			:showActionButton="false"
			:showSearch="false"
		/>

		<Card class="mt-3">
			<template #content>
				<div class="d-flex flex-wrap">
					<div class="col-12 col-md-4">
						<h3 class="text-primary">Comunicación</h3>
					</div>
					<div class="col-12 col-md-8">
						<h3 class="text-primary">
							Gestiona a tiempo los problemas de rendimiento
						</h3>
					</div>
				</div>
				<div
					v-for="(wantsToImprove, index) in employee.wantsToImproves"
					:key="index"
					class="d-flex flex-wrap mt-4"
				>
					<div class="col-12 col-md-4 d-flex gap-2">
						<Checkbox v-model="checked[index]" :binary="true" />
						<p class="mb-0 text-primary">
							{{ wantsToImprove.guest.user.name }}
						</p>
					</div>
					<div class="col-12 col-md-8">
						<p class="text-primary">
							¿Oué crees que tiene que hacer para mejorar?
						</p>
						<p class="text-primary">
							<b>
								{{ wantsToImprove.what_do_you_think }}
							</b>
						</p>
						<p class="text-primary">¿Qué puedes hacer tú para ayudarle?</p>
						<p class="text-primary">
							<b>{{ wantsToImprove.what_you_can_do }}</b>
						</p>
					</div>
					<div
						v-if="index != employee.wantsToImproves.length - 1"
						class="col-12"
					>
						<hr />
					</div>
				</div>
				<div class="w-100 d-flex justify-content-center mt-3">
					<Button
						@click="addImprovementPlan()"
						label="Iniciar plan de mejora"
						class="bg-green"
					></Button>
				</div>
			</template>
		</Card>

		<!-- Observadores -->
		<TotalAndSearch
			class="mt-5"
			:title="'¿Quién quieres que te observe?'"
			:actionButtonLabel="'Añadir observador'"
			:showSearch="false"
		/>

		<div class="row mt-3">
			<div class="col-12 col-md-6 col-lg-4">
				<UserCard
					:showOverlay="false"
					:showStatus="false"
					:showAction="false"
				/>
			</div>
		</div>

		<!-- Planes de mejora -->
		<!-- <TotalAndSearch
			class="mt-5"
			:title="'Planes de mejora'"
			:showActionButton="false"
		/>

		<div class="row mt-3">
			<div class="col-12 col-md-6 col-lg-4">
				<UserCard
					:showOverlay="false"
					:showStatus="false"
					:showAction="true"
					:actionText="'Ver'"
				>
					<template #action>
						<Button
							@click="addWantsToImprove"
							label="Ver"
							class="bg-primary text-white"
						/>
					</template>
				</UserCard>
			</div>
		</div> -->
	</div>
</template>

<script>
	import Card from "primevue/card";
	import Checkbox from "primevue/checkbox";

	import UserCard from "@/components/user/partials/UserCard.vue";
	import TotalAndSearch from "@/components/user/partials/TotalAndSearch.vue";
	import ImprovementPlanDialog from "@/components/user/partials/dialogs/ImprovementPlanDialog.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Card,
			Checkbox,
			TotalAndSearch,
			UserCard,
			ImprovementPlanDialog,
		},
		data() {
			return {
				checked: [],
			};
		},
		methods: {
			...mapActions(["getImprovementPlans"]),
			...mapMutations(["changeCurrentDashboardTab", "toggleFormDialog"]),
			addImprovementPlan() {
				this.toggleFormDialog({
					stateVariable: "user_improvement_plans",
					show: true,
				});
			},
			addWantsToImprove() {
				this.toggleFormDialog({
					stateVariable: "user_wants_to_improve",
					show: true,
				});
			},
		},
		mounted() {
			this.getImprovementPlans();
		},
		computed: {
			...mapState(["employee"]),
		},
	};
</script>
