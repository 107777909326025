<template>
	<div>
		<!-- Pending feedbacks -->
		<TotalAndSearch
			class="mt-3"
			:title="'Registros'"
			:total="user_libraries.listTotal"
			:showActionButton="false"
			@search="search"
		/>

		<div v-if="user_libraries.list?.length" class="row mt-0 gy-4">
			<div
				v-for="(library, index) in user_libraries.list"
				:key="index"
				class="col-12 col-md-6 col-lg-4"
			>
				<LibraryElement
					:image="library.imageBase64"
					:title="library.title"
					:description="library.description"
					:link="library.link"
					:file="library.file"
				/>
			</div>

			<div
				v-if="showLoadMore"
				class="col-12 d-flex justify-content-center mt-5"
			>
				<Button
					@click="loadMore"
					label="Ver más"
					class="bg-primary text-white"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import LibraryElement from "@/components/user/partials/LibraryElement.vue";
	import TotalAndSearch from "@/components/user/partials/TotalAndSearch.vue";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			LibraryElement,
			TotalAndSearch,
		},
		data() {
			return {
				rows: 3,
				page: 0,
				lastSearch: null,
			};
		},
		methods: {
			...mapActions(["getRegisters"]),
			...mapMutations(["toggleLoading"]),
			loadMore() {
				this.toggleLoading();

				this.page++;

				this.getRegisters({
					stateVariable: "user_libraries",
					route: "user-libraries",
					page: this.page,
					rows: this.rows,
					concatList: true,
					search: this.lastSearch,
				}).then(() => {
					this.toggleLoading();
				});
			},
			search(params) {
				this.lastSearch = params;
				this.toggleLoading();

				this.page = 0;

				this.getRegisters({
					stateVariable: "user_libraries",
					route: "user-libraries",
					page: this.page,
					rows: this.rows,
					search: params,
				}).then(() => {
					this.toggleLoading();
				});
			},
		},
		computed: {
			...mapState(["user_libraries"]),
			showLoadMore() {
				return this.user_libraries.listTotal > this.rows * (this.page + 1);
			},
		},
		mounted() {
			this.getRegisters({
				stateVariable: "user_libraries",
				route: "user-libraries",
				page: this.page,
				rows: this.rows,
			});
		},
	};
</script>
