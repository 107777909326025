<template>
	<div>
		<div
			v-if="
				!startFeedback.showStartFeedback &&
				(manager.viewingEmployeeDashboard ||
					userLogged.role.name == 'empleado' ||
					userLogged.role.name == 'invitado')
			"
		>
			<UserInformation />

			<div>
				<TabView :activeIndex="dashboard.currentTab" lazy>
					<!-- ¿Cómo empezar? -->
					<TabPanel v-if="howToStart">
						<HowToStart class="mt-5" />
					</TabPanel>

					<!-- Invitados -->
					<TabPanel v-if="guests">
						<Guests class="mt-5" />
					</TabPanel>

					<!-- Feedback -->
					<TabPanel v-if="feedback">
						<GuestFeedback
							v-if="userLogged.role.name == 'invitado'"
							class="mt-5"
						/>
						<EmployeeFeedback v-else class="mt-5" />
					</TabPanel>

					<!-- Plan de mejora -->
					<TabPanel v-if="improvementPlan">
						<GuestImprovementPlan
							v-if="userLogged.role.name == 'invitado'"
							class="mt-5"
						/>
						<ImprovementPlan v-else class="mt-5" />
					</TabPanel>

					<!-- Biblioteca -->
					<TabPanel v-if="library">
						<Library class="mt-5" />
					</TabPanel>
				</TabView>
			</div>
		</div>

		<Employees
			v-else-if="
				!startFeedback.showStartFeedback && userLogged.role.name == 'manager'
			"
		/>

		<StartFeedback v-if="startFeedback.showStartFeedback" class="mt-5" />
	</div>
</template>

<script>
	import TabView from "primevue/tabview";
	import TabPanel from "primevue/tabpanel";

	import UserInformation from "@/components/user/dashboard/UserInformation.vue";
	import Employees from "@/components/user/dashboard/Employees.vue";
	import HowToStart from "@/components/user/dashboard/HowToStart.vue";
	import Guests from "@/components/user/dashboard/Guests.vue";
	import EmployeeFeedback from "@/components/user/feedback/EmployeeFeedback.vue";
	import GuestFeedback from "@/components/user/feedback/GuestFeedback.vue";
	import StartFeedback from "@/components/user/partials/feedback_form/StartFeedback.vue";
	import ImprovementPlan from "@/components/user/improvement_plan/ImprovementPlan.vue";
	import GuestImprovementPlan from "@/components/user/improvement_plan/GuestImprovementPlan.vue";
	import Library from "@/components/user/library/Library.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			TabView,
			TabPanel,
			UserInformation,
			Employees,
			HowToStart,
			Guests,
			EmployeeFeedback,
			GuestFeedback,
			StartFeedback,
			ImprovementPlan,
			GuestImprovementPlan,
			Library,
		},
		data() {
			return {
				howToStart: true,
				guests: true,
				feedback: true,
				improvementPlan: true,
				library: true,
			};
		},
		methods: {
			...mapActions(["feedbackSections"]),
			...mapMutations(["changeManagerViewingEmployeeDashboard"]),
		},
		computed: {
			...mapState(["dashboard", "userLogged", "manager", "startFeedback"]),
		},
		mounted() {
			if (this.userLogged.role.name == "manager") {
				this.howToStart = false;
			}

			// if (this.userLogged.role.name == "empleado") {
			// 	this.howToStart = false;
			// }

			if (this.userLogged.role.name == "invitado") {
				this.howToStart = false;
				this.guests = false;
			}

			this.feedbackSections();
		},
	};
</script>

<style lang="scss" scoped>
	.p-tabview {
		box-shadow: none;
		border-radius: unset;

		:deep(.p-tabview-nav-container) {
			display: none;
		}

		:deep(.p-tabview-panels) {
			padding: 0;
			border: none;
			background: transparent;
		}
	}
</style>
