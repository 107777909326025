<template>
	<div>
		<div class="top-section"></div>
		<div
			class="bottom-section d-flex justify-content-center align-items-center"
		>
			<div class="content d-flex flex-column gap-3 p-3">
				<h1 class="text-white">Iniciar feedback</h1>
				<!-- <h1 class="text-white typed"></h1> -->
				<div
					class="d-flex flex-wrap align-items-center justify-content-between gap-2"
				>
					<div class="d-flex flex-wrap align-items-center gap-3">
						<Avatar size="large" shape="circle" :label="firstLetter" />
						<div class="d-flex flex-column text-white">
							<h4 class="mb-1">{{ guest.selectedEmployee.user.name }}</h4>
							<p class="mb-0">{{ guest.selectedEmployee.job }}</p>
						</div>
					</div>
					<Button
						ref="startFeedbackButton"
						@click="
							changeCurrentStartFeedbackSlide(startFeedback.currentSlide + 1)
						"
						class="bg-yellow mt-3 mt-md-0"
						label="Comenzar"
						icon="pi pi-reply"
						iconPos="right"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Button from "primevue/button";
	import { mapMutations, mapState } from "vuex";
	// import Typed from "typed.js";

	export default {
		components: {
			Avatar,
			Button,
		},
		methods: {
			...mapMutations(["changeCurrentStartFeedbackSlide"]),
		},
		computed: {
			...mapState(["startFeedback", "guest"]),
			firstLetter() {
				return this.guest.selectedEmployee.user.name[0];
			},
		},
		mounted() {
			// const options = {
			// 	strings: ["Iniciar feedback"],
			// 	typeSpeed: 75,
			// 	showCursor: false,
			// };
			// new Typed(".typed", options);

			// if user press "enter" key, trigger click event
			document.addEventListener("keydown", (e) => {
				if (e.key === "Enter" && this.startFeedback.currentSlide === 0) {
					this.$refs.startFeedbackButton?.$el?.click();
				}
			});
		},
	};
</script>

<style lang="scss" scoped>
	.top-section {
		background-image: url("@/assets/images/misc/background-1.jpg");
		background-position: center;
		background-size: cover;
		width: 100%;
		height: 50%;
	}

	.bottom-section {
		width: 100%;
		height: 50%;

		h1 {
			font-size: 5rem;
		}

		:deep(.p-button) {
			.pi {
				transform: rotate(180deg);
			}
		}
	}

	// Mobiles
	@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.top-section {
			display: none;
		}

		.bottom-section {
			height: 100%;
			background-image: url("@/assets/images/misc/background-1.jpg");
			background-position: center;
			background-size: cover;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
			}

			.content {
				position: relative;
				z-index: 5;

				h1 {
					font-size: 3rem;
				}
			}
		}
	}
</style>
