import Consts from "../consts";

const defaults = [
	"customers",
	"roles",
	"permissions",
	"users",
	"managers",
	"employees",
	"libraries",
	"user_guests",
	"user_defined_guests",
	"user_improvement_plans",
	"user_wants_to_improve",
	"user_employees",
	"user_feedback",
	"user_libraries",
	"guest_pending_feedbacks",
	"guest_completed_feedbacks",
];

const state = {
	baseURL: Consts.defaultApiRoute,
	showSidebar: false,
	userLogged: null,
	loading: false,

	// Datatable defaults
	datatableDefaults: {
		noResults: "No hay resultados",
		lazy: true,
		loading: true,
		paginator: true,
		rows: 5,
		rowsPerPageOptions: [5, 10, 20],
		rowHover: true,
		scrollable: true,
		scrollHeight: "600px",
		currentPage: 0,
		paginatorTemplate:
			"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
		currentPageReportTemplate:
			"Mostrando del {first} al {last} de un total de {totalRecords} registros",
		responsiveLayout: "stack",
		breakpoint: "960px",
	},

	// Dialogs defaults
	dialogDefaults: {
		modal: true,
		closeOnEscape: true,
		dismissableMask: true,
	},

	// Users layout
	// Dashboard
	dashboard: {
		currentTab: 0,
		showSidebar: false,
	},


	// Start feedback
	startFeedback: {
		totalSlides: 5, // Total of slides, including the start slide and the end slide
		currentSlide: 0,
		showStartFeedback: false,
		sectionsNames: [],

		// Communication slide
		communicationQuestions: [],
		communicationRatings: [],

		// Behavior slide
		behaviorQuestions: [],
		behaviorRatings: [],

		// Performance slide
		performanceQuestions: [],
		performanceRatings: [],
	},

	// Guest
	guest: {
		selectedEmployee: null,
	},

	// Manager
	manager: {
		viewingEmployeeDashboard: false,
		selectedEmployee: null,
	},

	// Employee
	employee: {
		feedbackQuestionAnswers: [],
		totalFeedbacks: 0,
		wantsToImproves: [],
	},
};

for (const item of defaults) {
	state[item] = {
		// Table
		list: [],
		listTotal: 0,
		currentPage: 0,
		rows: 5,

		// Form
		register: null,
		dialog: false,
		dialogMode: "show",
	};
}

export default state;
