<template>
	<Dialog
		v-model:visible="$store.state.users.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle class="text-white" :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 py-3" :class="[loading ? 'invisible' : '']">
				<div class="col-12">
					<InputText
						ref="name"
						:label="'Nombre y apellidos'"
						:disabled="disabled"
						:error="form.errors.get('name')"
						@change-value="(value) => (form.name = value)"
					/>
				</div>
				<div v-if="!userLoggedIsCustomer && !isCustomer" class="col-12">
					<DropDown
						ref="role_id"
						label="Rol"
						:options="filteredRoles"
						:displayText="'slug'"
						:disabled="disabled"
						:error="form.errors.get('role_id')"
						@change-value="(value) => (form.role_id = value)"
					/>
				</div>
				<div v-if="showCustomerDropdown && !isCustomer" class="col-12">
					<DropDown
						ref="customer_id"
						label="Empresa"
						:options="customers.list"
						:displayText="'name'"
						:disabled="disabled"
						:error="form.errors.get('customer_id')"
						@change-value="(value) => (form.customer_id = value)"
					/>
				</div>
				<div v-if="!userLoggedIsCustomer && form.role_id == 4" class="col-12">
					<DropDown
						ref="manager_id"
						label="Mánager"
						:options="filteredManagers"
						:displayText="'name'"
						:disabled="disabled"
						:error="form.errors.get('manager_id')"
						@change-value="(value) => (form.manager_id = value)"
					/>
				</div>
				<div v-if="!userLoggedIsCustomer && form.role_id == 4" class="col-12">
					<InputText
						ref="functions"
						label="Funciones"
						:disabled="disabled"
						:error="form.errors.get('functions')"
						@change-value="(value) => (form.functions = value)"
					/>
				</div>
				<div v-if="!userLoggedIsCustomer && form.role_id == 4" class="col-12">
					<InputText
						ref="phone"
						label="Teléfono"
						:disabled="disabled"
						:error="form.errors.get('phone')"
						@change-value="(value) => (form.phone = value)"
					/>
				</div>
				<div v-if="!userLoggedIsCustomer && form.role_id == 5" class="col-12">
					<DropDown
						ref="employee_id"
						label="Empleado"
						:options="filteredEmployees"
						:displayText="'name'"
						:disabled="disabled"
						:error="form.errors.get('employee_id')"
						@change-value="(value) => (form.employee_id = value)"
					/>
				</div>
				<div
					v-if="
						!userLoggedIsCustomer && (form.role_id == 4 || form.role_id == 5)
					"
					class="col-12"
				>
					<InputText
						ref="job"
						label="Puesto de trabajo"
						:disabled="disabled"
						:error="form.errors.get('job')"
						@change-value="(value) => (form.job = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="email"
						label="Email"
						:disabled="disabled"
						:error="form.errors.get('email')"
						@change-value="(value) => (form.email = value)"
					/>
				</div>
				<div class="col-12">
					<InputPassword
						ref="password"
						label="Contraseña"
						:disabled="disabled"
						:error="form.errors.get('password')"
						@change-value="(value) => (form.password = value)"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-end">
				<Button @click="save()" label="Enviar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "usuario",
			title: `Añadir usuario`,
			disabled: false,
			isCustomer: false,
			loading: false,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.users.register != null;
				const url = `/users${update ? `/${this.users.register.id}` : ""}`;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.users.currentPage,
							rows: this.users.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						if (this.$refs[key] != null) {
							this.$refs[key].model = null;
						}
					}
				}
			},
			show() {
				this.loading = true;

				let rolesReady,
					customersReady,
					managersReady,
					employeesReady = false;

				this.isCustomer = false;

				this.clearForm();

				this.getRegisters({
					route: "/roles",
					stateVariable: "roles",
					getAll: true,
					showLoading: false,
				}).then(() => {
					rolesReady = true;
				});

				this.getRegisters({
					route: "/customers",
					stateVariable: "customers",
					getAll: true,
					showLoading: false,
				}).then(() => {
					customersReady = true;
				});

				this.getRegisters({
					route: "/all-managers",
					stateVariable: "managers",
					getAll: true,
					showLoading: false,
				}).then(() => {
					managersReady = true;
				});

				this.getRegisters({
					route: "/all-employees",
					stateVariable: "employees",
					getAll: true,
					showLoading: false,
				}).then(() => {
					employeesReady = true;
				});

				const interval = setInterval(() => {
					if (rolesReady && customersReady && managersReady && employeesReady) {
						clearInterval(interval);
						this.loading = false;

						const register = this.users.register;

						if (register != null) {
							for (const key in register) {
								if (
									Object.hasOwnProperty.call(register, key) &&
									this.$refs[key] != undefined
								) {
									this.$refs[key].model = register[key];
								}
							}

							this.isCustomer = register.role.name == "admin";

							this.$refs["role_id"].setValue(register.role_id);

							if (register.role_id != 1) {
								if (register.customer_id != null) {
									this.$refs["customer_id"].setValue(register.customer_id);
								} else {
									this.$refs["customer_id"].setValue(
										register.customer_has_user.customer_id
									);
								}
							}

							this.$nextTick(() => {
								if (register.employee != null) {
									this.$refs["manager_id"].setValue(register.responsible_id);
									this.$refs["functions"].model = register.employee.functions;
									this.$refs["phone"].model = register.employee.phone;
									this.$refs["job"].model = register.employee.job;
								}

								if (register.guest != null) {
									this.$refs["employee_id"].setValue(register.responsible_id);
									this.$refs["job"].model = register.guest.job;
								}
							});

							if (this.users.dialogMode == "edit") {
								this.title = `Editar ${this.modelName}`;
								this.disabled = false;
							} else {
								this.title = `Ver ${this.modelName}`;
								this.disabled = true;
							}
						}
					}
				}, 10);
			},
		},
		computed: {
			...mapState([
				"dialogDefaults",
				"users",
				"roles",
				"customers",
				"userLogged",
				"managers",
				"employees",
			]),
			userLoggedIsCustomer() {
				return this.userLogged.role.name == "admin";
			},
			showCustomerDropdown() {
				return !this.userLoggedIsCustomer && this.form.role_id != 1;
			},
			filteredRoles() {
				return this.roles.list.filter((role) => role.name != "admin");
			},
			filteredManagers() {
				return this.managers.list.filter(
					(manager) =>
						manager.customer_has_user.customer_id == this.form.customer_id
				);
			},
			filteredEmployees() {
				return this.employees.list.filter(
					(employee) =>
						employee.customer_has_user.customer_id == this.form.customer_id
				);
			},
		},
		watch: {
			showCustomerDropdown() {
				if (!this.showCustomerDropdown) {
					this.form.customer_id = null;
				}
			},
		},
	};
</script>
