<template>
	<div :style="cssProps">
		<StarRating
			:rating="value"
			class="custom-start-rating"
			:show-rating="false"
			:read-only="readonly"
			:star-size="realStartSize"
			:active-color="starColor"
			:inactive-color="inactiveColor"
			:star-points="[
				23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31,
				17,
			]"
			:round-start-rating="roundStartRating"
			@update:rating="setRating"
			:border-width="isMobile ? 1 : 0"
			:border-color="starColor"
		/>
	</div>
</template>

<script>
	import StarRating from "vue-star-rating";

	export default {
		components: {
			StarRating,
		},
		props: {
			readonly: {
				type: Boolean,
				default: false,
			},
			starSize: {
				type: Number,
				default: 30,
			},
			starColor: {
				type: String,
				default: "#ff9800",
			},
			inactiveColor: {
				type: String,
				default: "#333",
			},
			roundStartRating: {
				type: Boolean,
				default: true,
			},
			defaultValue: {
				type: Number,
				default: null,
			},
			margin: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				value: null,
			};
		},
		computed: {
			isMobile() {
				return window.innerWidth <= 768;
			},
			realStartSize() {
				return this.isMobile ? this.starSize * 0.6 : this.starSize;
			},
			cssProps() {
				return {
					"--margin-right": this.margin + "rem",
				};
			},
		},
		mounted() {
			if (this.defaultValue) {
				this.value = this.defaultValue;
			}
		},
		methods: {
			setRating(rating) {
				this.value = rating;
				this.$emit("change-value", this.value);
			},
		},
		watch: {
			defaultValue: {
				handler(newValue) {
					this.value = newValue;
				},
				deep: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.custom-start-rating {
		:deep(.vue-star-rating) {
			width: 100%;
			justify-content: space-between;

			.vue-star-rating-star {
				margin-right: var(--margin-right) !important;

				&:last-child {
					margin-right: 0 !important;
				}
			}
		}
	}
</style>
