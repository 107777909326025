<template>
	<div>
		<LibrariesTable
			:route="route"
			:stateVariable="stateVariable"
		/>
		<LibrariesForm
			:route="route"
			:stateVariable="stateVariable"
		/>
	</div>
</template>

<script>
	import LibrariesTable from "./LibrariesTable.vue";
	import LibrariesForm from "./LibrariesForm.vue";

	export default {
		components: {
			LibrariesTable,
			LibrariesForm,
		},
		data() {
			return {
				route: "libraries",
				stateVariable: "libraries",
			};
		},
	};
</script>
