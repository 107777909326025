<template>
	<Dialog
		v-model:visible="$store.state.customers.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle class="text-white" :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 py-3">
				<div class="col-12">
					<InputText
						ref="name"
						:label="'Nombre de la empresa'"
						:disabled="disabled"
						:error="form.errors.get('name')"
						@change-value="(value) => (form.name = value)"
					/>
				</div>
				<div class="col-12 mt-4">
					<InputText
						ref="employees_number"
						label="Número de empleados"
						:disabled="disabled"
						:error="form.errors.get('employees_number')"
						@change-value="(value) => (form.employees_number = value)"
					/>
				</div>
				<div v-if="customers.register == null" class="col-12 mt-4">
					<InputText
						ref="email"
						label="Correo electrónico"
						:disabled="disabled"
						:error="form.errors.get('email')"
						@change-value="(value) => (form.email = value)"
					/>
				</div>
				<div v-if="customers.register == null" class="col-12 mt-4">
					<InputPassword
						ref="password"
						label="Contraseña"
						:disabled="disabled"
						:error="form.errors.get('password')"
						@change-value="(value) => (form.password = value)"
					/>
				</div>
				<div v-if="customers.register == null" class="col-12 mt-4">
					<InputPassword
						ref="password_confirm"
						label="Repetir contraseña"
						:disabled="disabled"
						:error="form.errors.get('password_confirm')"
						:feedback="false"
						@change-value="(value) => (form.password_confirm = value)"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-end">
				<Button @click="save()" label="Enviar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "empresa",
			title: `Añadir empresa`,
			disabled: false,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.customers.register != null;
				const url = `/customers${
					update ? `/${this.customers.register.id}` : ""
				}`;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.customers.currentPage,
							rows: this.customers.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						if (this.$refs[key] != null) {
							this.$refs[key].model = null;
						}
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.customers.register;

				if (register != null) {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}

					if (this.customers.dialogMode == "edit") {
						this.title = `Editar ${this.modelName}`;
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "customers"]),
		},
	};
</script>
