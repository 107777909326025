<template>
	<div>
		<label for="input" class="text-primary text-bold">{{ label }}</label>

		<SelectButton
			v-model="model"
			:disabled="disabled"
			:options="values"
			optionLabel="label"
			:multiple="multiple"
			:class="[error != null ? 'p-invalid' : '']"
		/>

		<div v-if="error != null" class="text-danger">
			{{ error }}
		</div>
	</div>
</template>

<script>
	import SelectButton from "primevue/selectbutton";

	export default {
		components: {
			SelectButton,
		},
		props: {
			label: {
				type: String,
				required: true,
			},
			error: {
				type: String,
				default: null,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			values: {
				type: Array,
				required: true,
			},
			multiple: {
				type: Boolean,
				default: true,
			},
		},
		data: () => ({
			model: null,
		}),
		methods: {
			setValue(value) {
				this.values.forEach((option) => {
					if (option.value == value) {
						this.model = option;
					}
				});
			},
		},
		watch: {
			model(newValue) {
				if (newValue == null) {
					this.$emit("change-value", null);
				} else {
					this.$emit("change-value", newValue.value);
				}
			},
		},
	};
</script>
