<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-primary title">
				Crear nueva cuenta de empresa
			</div>
			<div class="col-12">
				<p class="text-muted">Introduce tus datos para crear tu cuenta</p>
			</div>
			<div class="col-12">
				<InputText
					label="Correo electrónico"
					:error="form.errors.get('email')"
					@change-value="(value) => (form.email = value)"
				/>
			</div>
			<InputText class="d-none" />
			<div class="col-12 d-flex justify-content-between mt-4">
				<Button
					:disabled="form.busy"
					@click="$parent.showForm = 'login'"
					label="Volver"
					class="bg-yellow"
				/>
				<Button
					@click="save()"
					label="Enviar"
					class="bg-salmon"
					:disabled="form.busy"
				/>
			</div>
		</div>
	</form>
</template>

<script>
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		data() {
			return {
				form: new Form(),
			};
		},
		methods: {
			...mapActions(["sendForm"]),
			save() {
				const url = `/send-forget-password-email`;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.$parent.showSendForgotPasswordEmail();

						this.$nextTick(() => {
							this.$parent.showForm = "login";
						});
					} else {
						this.$parent.showError();
					}
				});
			},
		},
	};
</script>
